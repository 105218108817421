import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as uuid from 'uuid';
import { jsonpFactory } from '@angular/http/src/http_module';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { NavController } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataResult } from 'src/app/core/models/gclaims.model';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { InspectionService } from 'src/app/core/services/inspection.service';


interface viaCepI {
  bairro: string,
  cep: string,
  complemento: string,
  ddd: string,
  gia: string,
  ibge: string,
  localidade: string,
  logradouro: string,
  siafi: string,
  uf: string
}

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  private inspectionData: BehaviorSubject<DataResult>
  public inspectionUniqueId = new BehaviorSubject(null);
  public user = new BehaviorSubject(null);
  public position: BehaviorSubject<any> = new BehaviorSubject(null);
  public videoComponent: BehaviorSubject<any> = new BehaviorSubject(true);
  public eventSubmit = new EventEmitter()
  private pendingMap = new Map();

  constructor(private http: HttpClient, public navCtrl: NavController, public route: ActivatedRoute) {
    this.inspectionData = new BehaviorSubject<DataResult>(this.parseInspectionData(this.inspectionData));
    this.inspectionUniqueId = new BehaviorSubject(atob(localStorage.getItem('inspectionUniqueId')))
    this.user = new BehaviorSubject(this.parseUserData(this.user));
    if (this.user.value) {
      if (this.user.value.name == null || this.user.value.name == '') {
        this.user.value.name = this.inspectionData.value.InsuredName
      }
    }
  }

  public get getInspectionTheme(): any {
    let inspection:any = this.inspectionData.value;
    let theme = {
      logo:'gclaims_logo-white.png'
    };

    if((inspection || {}).theme != null){
      return {...theme,...inspection.theme};
    }
    return theme;
  }

  public get getCurrentInspectionData(): DataResult {
    return this.inspectionData.value;
  }

  public set setCurrentInspectionData(data) {
    this.inspectionData.next(data);
  }

  public set setInspectionUniqueId(data: string) {
    localStorage.setItem('inspectionUniqueId', btoa(data))
    this.inspectionUniqueId.next(data)
  }

  public set setCurrentUser(data: any) {
    localStorage.setItem('user', btoa(JSON.stringify(data)));
    this.user.next(data);
  }

  searchAddressByCep(cep: any): Observable<any> {
    cep = cep.replace(/\D/g, '');
    const validacep = /^[0-9]{8}$/;
    var params = { key: 'AIzaSyBWFwpZToKrM17od3hu-C75eqTCKcF-N5c' };
    if (validacep.test(cep)) {
      return this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?&address=${cep}`, { params: params })
    }
    return of([]);
  }

  searchAddressesLatLng(latitude, longitude): Observable<any> {
    var params = { key: 'AIzaSyBWFwpZToKrM17od3hu-C75eqTCKcF-N5c' };
    // Chama não autorizada
    return this.http.get(
      'https://maps.googleapis.com/maps/api/geocode/json?&address=' + latitude + '%20' + longitude,
      { params: params }
    );
  }

  generateUUID() {
    return uuid.v4();
  }

  navigate(path: string, options?: NavigationOptions) {
    if(!this.route.firstChild.snapshot.params.anotherPath) {
      this.navCtrl.navigateForward(`${this.route.firstChild.snapshot.params.uniqueId}/${path}`, { replaceUrl: true, ...options });
    } else {
      this.navCtrl.navigateForward(`${this.route.firstChild.snapshot.params.uniqueId}/${String(this.route.firstChild.snapshot.params.anotherPath).toLowerCase()}/${path}`, { replaceUrl: true, ...options });
    }
  }
  navigateAnother(path: string,another,  options?: NavigationOptions) {
      this.navCtrl.navigateForward(`${this.route.firstChild.snapshot.params.uniqueId}/${another}/${path}`, { replaceUrl: true, ...options });
  }

  navigateRoot(path: string, options?: NavigationOptions) {
    this.navCtrl.navigateRoot(`${this.route.firstChild.snapshot.params.uniqueId}/${path}`, { replaceUrl: true, ...options });
  }


  mountPendingMap() {
    const str = localStorage.getItem('pending-map');
    if (str != null) {
      const keyValuePair = (str.slice(0, -1).split(/\s*,\s*/).map(chunk => chunk.split('=')) as any);
      return new Map(keyValuePair);
    }
    return new Map()
  }

  private parseInspectionData(inspectionData): DataResult | null {
    const data = localStorage.getItem('inspection');
    return data != undefined ? JSON.parse(atob(data)) : null;
  }

  private parseUserData(inspectionData): any {
    const data = localStorage.getItem('user');
    return data != undefined ? JSON.parse(atob(data)) : null;
  }
  // copiado descaradamente da internet https://stackoverflow.com/questions/433627/concatenate-two-json-objects
// percorre o objeto que vc quer adicionar, setando a chave correspondente que é criada para o objeto incrementado
jsonConcat(o1, o2) {
  for (var key in o2) {
   o1[key] = o2[key];

  }
  
  return o1;
 }
 formatDateFile(dateFile){
  var date=dateFile.toString().split(' ')

  return date[2]+"/"+date[1]+"/"+date[3]
}
validHourFile(dateFile, previousTimeInMinutes){
  var hourFile = (dateFile.toString().split(' ')[4]).split(":")
  const currHour = (new Date().toString().split(' ')[4]).split(":")
  
  
  if(parseInt(hourFile[1]) > parseInt(currHour[1]) - previousTimeInMinutes && hourFile[0] == currHour[0]){
    return true
  } else if(hourFile[0] < currHour[0]){
    // caso a hora vire entre a foto ser tirada e o upload a gente soma 60 as minutos e faz a mesma comparação
    const min = parseInt(currHour[1]) + 60
    if(parseInt(hourFile[1]) > min - previousTimeInMinutes){
      return true
    }
  }
  return false
}
validDateFile(dateFile,previousTimeInMinutes = 5){
  const date = new Date()
  
  return this.formatDateFile(dateFile) == this.formatDateFile(date) && this.validHourFile(dateFile,previousTimeInMinutes)
}

}
