import { EventEmitter, Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { AbstractControl, FormArray } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { CoreService } from 'src/app/shared/services/core.service';
@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  inspectionId: string

  constructor(private firestore: AngularFirestore, private coreService: CoreService) {
    this.coreService.inspectionUniqueId.subscribe(uniqueId => {
      this.inspectionId = uniqueId
    })
  }

  updateUquipments = new EventEmitter();


  updateEquipment(payload: any) {
    if(payload.jsonAnalysis)
      payload.jsonAnalysis = JSON.parse(JSON.stringify(payload.jsonAnalysis as any));
    console.log(payload.jsonAnalysis)
    this.firestore.collection('inspection').doc(this.inspectionId).update(payload)
  }



}

