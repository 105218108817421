import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { DataResult } from 'src/app/core/models/gclaims.model';
import { EquipmentService } from 'src/app/core/services/equipment.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { PercentFormEquipmentService } from 'src/app/core/services/percentFormEquipment.service';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

interface vistory {
  img: string,
  device: string,
  situation: string,
  action: string

}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  vistorys: vistory[] = []
  vistoryStatus: boolean = false
  regVistory: boolean = false
  errorMsg: Error
  equipments: any[] = [];
  allCompleteInspection: boolean = false;
  inspectionData: DataResult;
  user: any
  simpleItems: any[] = [];
  filterItems: Map<any, any> = new Map();

  constructor(
    private coreService: CoreService,
    private equipmentService: EquipmentService,
    private load: LoaderService,
    private percentService: PercentFormEquipmentService,
    private sanitizer: DomSanitizer
  ) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getEquipments();
      this.getDevices();
    }, 500);
  }

  ngOnInit() {
    this.load.isLoading.next(true);
    let response = []
    this.inspectionData = this.coreService.getCurrentInspectionData;
    this.user = this.coreService.user.value
    this.prepareVistoryItem(response)
    this.getEquipments()
    this.getDevices();
  }

  private getEquipments() {
    // this.equipmentService.getEquipments().pipe(take(1)).subscribe(res => {
    //   this.equipments = []
    //   res.forEach(doc => {
    //     this.equipments.push({ id: doc.id, ...doc.data() })
    //   })
    //   this.load.isLoading.next(false)
    // }, (err) => console.log(err), () => {
    //   this.enableFinishInspection()
    // })
  }


  prepareVistoryItem(response) {


    if (response.length != 0) {
      this.regVistory = true
      let status: boolean
      status = true
      response.forEach((vistory) => {
        if (vistory.status == "open" && status) {
          this.vistoryStatus = true
          status = false
        }
        vistory.situation = vistory.status == "closed" ? "Completo" : "Em aberto"
        vistory.action = vistory.status == "closed" ? "Vizualizar" : "informações pendentes"
        this.vistorys.push(vistory)
      });
    } else {
      this.regVistory = false
    }

  }

  toRegister(id?: string, percentage?: string) {
    if (id == undefined) {
      this.coreService.navigate('register-equipment/first-step/0')
    }
    else if (id != undefined && percentage != '100%') {
      this.coreService.navigate(`register-equipment/first-step/${id}`)
    } else {
      this.coreService.navigate(`view-details/${id}`)
    }
  }

  finishInspection() {
    this.coreService.navigate('choose-method');
  }

  enableFinishInspection() {
    const filterCompleteInspection = this.equipments.filter(equi => equi.percentage != '100%');
    this.allCompleteInspection = filterCompleteInspection.length == 0 ? false : true;
  }

  private getDevices() {
    // this.equipmentService.getTypeDevices().pipe(take(1))
    //   .subscribe(res => {
    //     this.simpleItems = res.data()['devices'];
    //     this.simpleItems.forEach(el => {
    //       this.filterItems.set(el.name, el.image_url)
    //     })
    //     for (const equi of this.equipments) {
    //       equi.imageUrl = this.filterItems.get(equi.firstStep.typeDevice);
    //     }
    //   })
  }


}
