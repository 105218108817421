import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/shared/services/core.service';
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  logoUrl:any = "";
  constructor(private coreService: CoreService) { }

  ngOnInit() {
    const theme = JSON.parse(localStorage.getItem('theme'))

    this.logoUrl = theme?.logo ?? "assets/easy-inspect/images/gclaims_logo-white.png";
  }

  
}
