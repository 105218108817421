import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactModalComponent } from 'src/app/shared/components/contact-modal/contact-modal.component';

@Component({
  selector: 'app-vistory-not-enabled',
  templateUrl: './vistory-not-enabled.component.html',
  styleUrls: ['./vistory-not-enabled.component.scss'],
})
export class VistoryNotEnabledComponent implements OnInit {
  
  email: string
  phone: string;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.email = JSON.parse(localStorage.getItem('theme'))?.contact?.email ?? '';
    this.phone = JSON.parse(localStorage.getItem('theme'))?.contact?.phone ?? '';
  }

  async showModal() {
    const modal = await this.modalController.create({
      component: ContactModalComponent,
      cssClass: 'modal-custom-contact',
    })

    return await modal.present();
  }

}
