import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomThemeService {



  constructor(@Inject(DOCUMENT) private document: Document) { }

  loadTheme(theme) {

    if (theme != 'undefined' && theme ) {

      localStorage.setItem('theme', JSON.stringify(theme));
      if(theme.bgDefault){

        this.document.documentElement.style.setProperty('--bgDefault', theme.bgDefault);
      }
      if(theme.bgQuiz){

        this.document.documentElement.style.setProperty('--bgQuiz', theme.bgQuiz);
      }
      if(theme.bgPrimaryButton){

        this.document.documentElement.style.setProperty('--bgPrimaryButton', theme.bgPrimaryButton);
      }
      if(theme.colorPrimaryButton){

        this.document.documentElement.style.setProperty('--colorPrimaryButton', theme.colorPrimaryButton);
      }
      if(theme.bgSecondaryButton){

        this.document.documentElement.style.setProperty('--bgSecondaryButton', theme.bgSecondaryButton);
      }
      if(theme.colorSecondaryButton){

        this.document.documentElement.style.setProperty('--colorSecondaryButton', theme.colorSecondaryButton);
      }
      if(theme.bgInput){

        this.document.documentElement.style.setProperty('--bgInput', theme.bgInput);
      }
      if(theme.colorInput){

        this.document.documentElement.style.setProperty('--colorInput', theme.colorInput);
      }
      if(theme.bgToolbar){

        this.document.documentElement.style.setProperty('--bgToolbar', theme.bgToolbar);
      }
      if(theme.textColor){

        this.document.documentElement.style.setProperty('--textColor', theme.textColor);
      }
  
      if(theme.bgCheckbox){

        this.document.documentElement.style.setProperty('--bgCheckbox', theme.bgCheckbox);
      }
      if(theme.bgRadio){

        this.document.documentElement.style.setProperty('--bgRadio', theme.bgRadio);
      }
      if(theme.bgToggleButton){

        this.document.documentElement.style.setProperty('--bgToggleButton', theme.bgToggleButton);
      }
      if(theme.toggleButtonTextColor){

        this.document.documentElement.style.setProperty('--toggleButtonTextColor', theme.toggleButtonTextColor);
      }
      if(theme.toolbarTextColor){

        this.document.documentElement.style.setProperty('--toolbarTextColor', theme.toolbarTextColor);
      }
      if(theme.inputTextColor){
        this.document.documentElement.style.setProperty('--inputTextColor', theme.inputTextColor);
      }
      if(theme.inputBorderColor){

        this.document.documentElement.style.setProperty('--inputBorderColor', theme.inputBorderColor);
      }
    }
    /** **/
  }

  getTheme() {
    const theme = localStorage.getItem('theme');
    if (theme != 'undefined') {
      this.loadTheme(JSON.parse(theme));
    }
  }
}
