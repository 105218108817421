import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class ToggleService implements IService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

    getTitle = (): string => 'Toggle';

    getAllThemes = (): Array<any> => {
        return [
            { 'url': 'toggle/0', 'title': 'Simple', 'theme': 'layout1' },
            { 'url': 'toggle/1', 'title': 'Description', 'theme': 'layout2' },
            { 'url': 'toggle/2', 'title': 'Group', 'theme': 'layout3' },
        ];
    }

    getDataForTheme = (menuItem: any): any => {
        return this[
            'getDataFor' +
            menuItem.theme.charAt(0).toUpperCase() +
            menuItem.theme.slice(1)
        ]();
    }

    //* Data Set for page 1
    getDataForLayout1 = (): any => {
        return {
            'toolbarTitle': 'Simple',
            'items': [
                {
                    'id': 0,
                    'title': 'Allow members to search',
                    'isChecked': true
                },
                {
                    'id': 1,
                    'title': 'Upcoming Classes',
                    'isChecked': false
                },
                {
                    'id': 2,
                    'title': 'Favourite Classes',
                    'isChecked': false
                },
                {
                    'id': 3,
                    'title': 'Enable Notifications',
                    'isChecked': true
                }
            ]
        };
    }

    //* Data Set for page 2
    getDataForLayout2 = (): any => {
        return {
            'toolbarTitle': 'Description',
            'items': [
                {
                    'id': 1,
                    'title': 'Allow members to search',
                    'subtitle': 'Lorem ipsum dolor sit amet, eget consectetur adipiscing elit, sed id.',
                    'isChecked': true
                },
                {
                    'id': 2,
                    'title': 'Upcoming Classes',
                    'subtitle': 'Lorem ipsum dolor sit amet, eget consectetur adipiscing elit, sed id.',
                    'isChecked': false
                },
                {
                    'id': 4,
                    'title': 'Favourite Classes',
                    'subtitle': 'Lorem ipsum dolor sit amet, eget consectetur adipiscing elit, sed id.',
                    'isChecked': false
                },
                {
                    'id': 3,
                    'title': 'Enable Notification',
                    'subtitle': 'Lorem ipsum dolor sit amet, eget consectetur adipiscing elit, sed id.',
                    'isChecked': false
                },

            ]
        };
    }

    //* Data Set for page 3
    getDataForLayout3 = (): any => {
        return {
            'toolbarTitle': 'Group',
            'titleLike': 'Like Notifications',
            'titleComment': 'Comment Notifications',
            'like': [
                {
                    'id': 0,
                    'title': 'From People I Follow',
                    'isChecked': true
                },
                {
                    'id': 1,
                    'title': 'From Everyone',
                    'isChecked': false
                },
                {
                    'id': 2,
                    'title': 'From Business Accounts',
                    'isChecked': false
                }
            ],
            'comment': [
                {
                    'id': 0,
                    'title': 'Likes',
                    'isChecked': true
                },
                {
                    'id': 1,
                    'title': 'Comments',
                    'isChecked': false
                },
                {
                    'id': 2,
                    'title': 'Follows',
                    'isChecked': false
                }
            ]
        };
    }

    load(item: any): Observable<any> {
        this.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('toggle/' + item.theme)
                    .valueChanges()
                    .subscribe(snapshot => {
                        this.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        this.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                this.loadingService.hide();
                observer.next(this.getDataForTheme(item));
                observer.complete();
            });
        }
    }
}
