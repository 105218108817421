import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class CheckBoxService implements IService {

  constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

  getTitle = (): string => 'Checkbox';

  getAllThemes = (): Array<any> => {
    return [
      { 'url': 'check-boxes/0', 'title': 'Simple Left', 'theme': 'layout1' },
      { 'url': 'check-boxes/1', 'title': 'Simple Right', 'theme': 'layout2' },
      { 'url': 'check-boxes/2', 'title': 'Avatar Left', 'theme': 'layout3' },
      { 'url': 'check-boxes/3', 'title': 'Avatar Right', 'theme': 'layout4' },
      { 'url': 'check-boxes/4', 'title': 'Icon Left', 'theme': 'layout5' },
      { 'url': 'check-boxes/5', 'title': 'Icon Right', 'theme': 'layout6' }
    ];
  }

  getDataForTheme = (menuItem: any): any => {
    return this[
      'getDataFor' +
      menuItem.theme.charAt(0).toUpperCase() +
      menuItem.theme.slice(1)
    ]();
  }

  //* Data Set for page 1
  getDataForLayout1 = (): any => {
    return {
      'toolbarTitle': 'Checkbox - Simple Left',
      'items':[
        {
          'id': 1,
          'title': 'Paris',
        },
        {
          'id': 2,
          'title': 'Alabama',
        },
        {
          'id': 3,
          'title': 'Brooklyn',
        },
        {
          'id': 4,
          'title': 'Chelsea',
        },
        {
          'id': 5,
          'title': 'Florence',
        },
        {
          'id': 6,
          'title': 'Ireland',
        }
      ]
    }
  }

  //* Data Set for page 2
  getDataForLayout2 = (): any => {
    return {
      'toolbarTitle': 'Checkbox - Simple Right',
      'items':[
        {
          'id': 1,
          'title': 'Paris',
        },
        {
          'id': 2,
          'title': 'Alabama',
        },
        {
          'id': 3,
          'title': 'Brooklyn',
        },
        {
          'id': 4,
          'title': 'Chelsea',
        },
        {
          'id': 5,
          'title': 'Florence',
        },
        {
          'id': 6,
          'title': 'Ireland',
        }
      ]
    }
  }

  //* Data Set for page 3
  getDataForLayout3 = (): any => {
    return {
      'toolbarTitle': 'Checkbox - Avatar Left',
      'items': [
          {
            'id': 1,
            'title': 'Paris',
            'image': 'assets/imgs/avatar/2.jpg' ,
          },
          {
             'id': 2,
             'title': 'Alabama',
             'image': 'assets/imgs/avatar/3.jpg',
           },
          {
            'id': 4,
            'title': 'Brooklyn',
            'image': 'assets/imgs/avatar/4.jpg' ,
          },
          {
            'id': 3,
            'title': 'Chelsea',
            'image': 'assets/imgs/avatar/5.jpg' ,
          },
          {
            'id': 5,
            'title': 'Florence',
            'image': 'assets/imgs/avatar/6.jpg' ,
          },
          { 'id': 6,
            'title': 'Ireland',
            'image': 'assets/imgs/avatar/7.jpg' ,
        },
      ]
    }
  }

  //* Data Set for page 4
  getDataForLayout4 = (): any => {
    return {
      'toolbarTitle': 'Checkbox - Avatar Right',
      'items': [
          {
            'id': 1,
            'title': 'Paris',
            'image': 'assets/imgs/avatar/2.jpg' ,
          },
          {
              'id': 2,
              'title': 'Alabama',
              'image': 'assets/imgs/avatar/3.jpg',
            },
          {
            'id': 4,
            'title': 'Brooklyn',
            'image': 'assets/imgs/avatar/4.jpg' ,
          },
          {
            'id': 3,
            'title': 'Chelsea',
            'image': 'assets/imgs/avatar/5.jpg' ,
          },
          {
            'id': 5,
            'title': 'Florence',
            'image': 'assets/imgs/avatar/6.jpg' ,
          },
          { 'id': 6,
            'title': 'Ireland',
            'image': 'assets/imgs/avatar/7.jpg' ,
        },
      ]
    }
  }

  //* Data Set for page 5
  getDataForLayout5 = (): any => {
    return {
      'toolbarTitle': 'Checkbox - Icon Left',
      'items': [
        { 'id': 1, 'title': 'Paris', 'icon': 'star' },
        { 'id': 2, 'title': 'Alabama', 'icon': 'pulse' },
        { 'id': 4, 'title': 'Brooklyn', 'icon': 'send' },
        { 'id': 3, 'title': 'Chelsea', 'icon': 'podium' },
        { 'id': 5, 'title': 'Florence', 'icon': 'star' },
        { 'id': 6, 'title': 'Ireland', 'icon': 'pulse' },
      ]
    }
  }

  //* Data Set for page 6
  getDataForLayout6 = (): any => {
    return {
      'toolbarTitle': 'Checkbox - Icon Right',
      'items': [
        { 'id': 1, 'title': 'Paris', 'icon': 'star' },
        { 'id': 2, 'title': 'Alabama', 'icon': 'pulse' },
        { 'id': 4, 'title': 'Brooklyn', 'icon': 'send' },
        { 'id': 3, 'title': 'Chelsea', 'icon': 'podium' },
        { 'id': 5, 'title': 'Florence', 'icon': 'star' },
        { 'id': 6, 'title': 'Ireland', 'icon': 'pulse' },
      ]
    }
  }

  load(item: any): Observable<any> {
    const that = this;
    that.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('checkBoxes/' + item.theme)
          .valueChanges()
          .subscribe(snapshot => {
            that.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            that.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        that.loadingService.hide();
        observer.next(this.getDataForTheme(item));
        observer.complete();
      });
    }
  }
}
