import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { DataResult } from 'src/app/core/models/gclaims.model';
import { ContactModalComponent } from 'src/app/shared/components/contact-modal/contact-modal.component';
import { CoreService } from 'src/app/shared/services/core.service';


@Component({
  selector: 'app-vistory-complete-successfull',
  templateUrl: './vistory-complete-successfull.component.html',
  styleUrls: ['./vistory-complete-successfull.component.scss'],
})
export class VistoryCompleteSuccessful implements OnInit {

  email: string
  phone: string;
  inspectionData: DataResult;
  constructor(private coreService: CoreService,private modalController: ModalController,) { }

  ngOnInit() {
    this.inspectionData = this.coreService.getCurrentInspectionData;
    this.email = JSON.parse(localStorage.getItem('theme'))?.contact?.email ?? '';
    this.phone = JSON.parse(localStorage.getItem('theme'))?.contact?.phone ?? '';
  }

  async showModal() {
    const modal = await this.modalController.create({
      component: ContactModalComponent,
      cssClass: 'modal-custom-contact',
    })

    return await modal.present();
  }

}
