import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooleanComponent } from './boolean/boolean.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { InputComponent } from './input/input.component';
import { CommentComponent } from './comment/comment.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { IonicModule } from '@ionic/angular';
import { DynamicFieldDirective } from '../../directives/dynamic-field.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadiogroupComponent } from './radiogroup/radiogroup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectiveDynamicFormDirective } from '../../directives/directive-dynamic-form.directive';
import { SubmitComponent } from './submit/submit.component';
import { FileComponent } from './file/file.component';
import { PanelComponent } from './panel/panel.component';
import { PanelDynamicComponent } from './paneldynamic/paneldynamic.component';
import { VideoComponent } from './video/video.component';
import { RecordModalComponent } from './video/record-modal/record-modal.component';
import { RequestDocumentsComponent } from './requestdocuments/requestdocuments.component';
import { RequestDocumentsUploadComponent } from './requestdocuments/requestdocuments-upload.component';
import { MatrixdropdownComponent } from './matrixdropdown/matrixdropdown.component';



@NgModule({
  declarations: [
    BooleanComponent,
    DropdownComponent,
    InputComponent,
    CommentComponent,
    DynamicFormComponent,
    RadiogroupComponent,
    DynamicFieldDirective,
    DirectiveDynamicFormDirective,
    CheckboxComponent,
    SubmitComponent,
    FileComponent,
    PanelComponent,
    PanelDynamicComponent,
    VideoComponent,
    RecordModalComponent,
    RequestDocumentsComponent,
    RequestDocumentsUploadComponent,
    MatrixdropdownComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule
  ],
  exports: [
    BooleanComponent,
    DropdownComponent,
    InputComponent,
    CommentComponent,
    DynamicFormComponent,
    DynamicFieldDirective,
    DirectiveDynamicFormDirective,
    RadiogroupComponent,
    CheckboxComponent,
    SubmitComponent,
    FileComponent,
    PanelComponent,
    PanelDynamicComponent,
    VideoComponent,
    RequestDocumentsComponent,
    RequestDocumentsUploadComponent,
    MatrixdropdownComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FormsModuleDynamic { }
