import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { ToastService } from './toast-service';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class GoogleCardsService implements IService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService, private toastCtrl: ToastService) { }

    getId = (): string => 'googleCards';

    getTitle = (): string => 'Cards';

    getAllThemes = (): Array<any> => {
        return [
            { 'url': 'google-card/0', 'title': 'Social - Simple', 'theme': 'layout1' },
            { 'url': 'google-card/1', 'title': 'Social - Author', 'theme': 'layout2' },
            { 'url': 'google-card/2', 'title': 'Social - Image Background', 'theme': 'layout3' },
            { 'url': 'google-card/3', 'title': 'Social - Thumb Card', 'theme': 'layout4' },
            { 'url': 'google-card/4', 'title': 'Social - Color Card', 'theme': 'layout5' },
            { 'url': 'google-card/5', 'title': 'eCommerce - Category', 'theme': 'layout6' },
            { 'url': 'google-card/6', 'title': 'eCommerce - Image Card', 'theme': 'layout7' },
            { 'url': 'google-card/7', 'title': 'eCommerce - Shopping Cart Card', 'theme': 'layout8' },
            { 'url': 'google-card/8', 'title': 'eCommerce - Thumb', 'theme': 'layout9' },
            { 'url': 'google-card/9', 'title': 'eCommerce - Simple ', 'theme': 'layout10' }
            
        ];
    }

    //* Data Set for page 1
    getDataForLayout1 = (): any => {
        return {
            'toolbarTitle' : 'Social - Simple',
            'items': [
                {
                    'id': 1,
                    'category': 'ART & DESIGN',
                    'title': 'A New Design Model That Helps People Change Their Lives',
                    'image': 'assets/imgs/background/4.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countLike': '78',
                    'countComment': '67',
                    'isLike': false
                },
                {
                    'id': 2,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/1.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countLike': '78',
                    'countComment': '67',
                    'isLike': false
                },
                {
                    'id': 3,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/2.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countLike': '78',
                    'countComment': '67',
                    'isLike': false
                },
                {
                    'id': 4,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/3.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countLike': '78',
                    'countComment': '67',
                    'isLike': false
                },
                {
                    'id': 5,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/4.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countLike': '78',
                    'countComment': '67',
                    'isLike': false
                },
                {
                    'id': 6,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/5.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countLike': '78',
                    'countComment': '67',
                    'isLike': false
                }
            ]
        };
    }

    //* Data Set for page 2
    getDataForLayout2 = (): any => {
        return {
            'toolbarTitle' : 'Social - Author',
            'items': [
                {
                    'id': 1,
                    'title': 'Molecule ionic New UI Kit',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/1.jpg',
                    'avatar': 'assets/imgs/avatar/1.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                },
                {
                    'id': 2,
                    'title': 'What’s new in ionic New?',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/2.jpg',
                    'avatar': 'assets/imgs/avatar/2.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                },
                {
                    'id': 3,
                    'title': 'What’s new in ionic New?',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/5.jpg',
                    'avatar': 'assets/imgs/avatar/3.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                },
                {
                    'id': 4,
                    'title': 'What’s new in ionic New?',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/3.jpg',
                    'avatar': 'assets/imgs/avatar/4.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                },
                {
                    'id': 5,
                    'title': 'What’s new in ionic New?',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/1.jpg',
                    'avatar': 'assets/imgs/avatar/5.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                }
            ]
        };
    }

    //* Data Set for page 3
    getDataForLayout3 = (): any => {
        return {
            'toolbarTitle' : 'Social - Image Background',
            'items': [
                {
                    'id': 1,
                    'image': 'assets/imgs/background/big-1.jpg',
                    'category': 'TECHNOLOGY',
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'btReadMore': 'Read More',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false

                },
                {
                    'id': 2,
                    'image': 'assets/imgs/background/big-2.jpg',
                    'category': 'TECHNOLOGY',
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'btReadMore': 'Read More',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 3,
                    'image': 'assets/imgs/background/big-3.jpg',
                    'category': 'TECHNOLOGY',
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'btReadMore': 'Read More',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 4,
                    'image': 'assets/imgs/background/big-6.jpg',
                    'category': 'TECHNOLOGY',
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'btReadMore': 'Read More',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 5,
                    'image': 'assets/imgs/background/big-4.jpg',
                    'category': 'TECHNOLOGY',
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'btReadMore': 'Read More',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 6,
                    'image': 'assets/imgs/background/big-5.jpg',
                    'category': 'TECHNOLOGY',
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'btReadMore': 'Read More',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 7,
                    'image': 'assets/imgs/background/big-1.jpg',
                    'category': 'TECHNOLOGY',
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'btReadMore': 'Read More',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 8,
                    'image': 'assets/imgs/background/big-2.jpg',
                    'category': 'TECHNOLOGY',
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'btReadMore': 'Read More',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                }
            ]
        };
    }

    //* Data Set for page 4
    getDataForLayout4 = (): any => {
        return {
            'toolbarTitle' : 'Social - Thumb Card',
            'items': [
                {
                    'id': 1,
                    'image': "assets/imgs/background/1.jpg",
                    'name': "Lorem Ipsum is simply dummy text",
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 2,
                    'image': "assets/imgs/background/2.jpg",
                    'name': "Electronic typesetting, remaining",
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 3,
                    'image': "assets/imgs/background/3.jpg",
                    'name': "Letraset sheets containing Lorem Ipsum passages",
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 4,
                    'image': "assets/imgs/background/4.jpg",
                    'name': "Contrary to popular belief, Lorem Ipsum",
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 5,
                    'image': "assets/imgs/background/5.jpg",
                    'name': "There are many variations of passages of",
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 6,
                    'image': "assets/imgs/background/6.jpg",
                    'name': "The standard chunk of used since",
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                },
                {
                    'id': 7,
                    'image': "assets/imgs/background/7.jpg",
                    'name': "It is a long established fact that",
                    'subtitle': 'How might we help people become better-informed citizens?',
                    'iconLike': 'heart',
                    'numberLike': '56',
                    'comments': 'chatbubble',
                    'numberCommnet': '65',
                    'isLike': false
                }
            ]
        };
    }

        //* Data Set for page 5
        getDataForLayout5  = (): any => {
            return {
              'toolbarTitle' : 'Social - Color Card',
              'items': [
                  {
                      'id': 1,
                      'category': 'TECHNOLOGY',
                      'subtitle': 'How might we help people become better-informed citizens?',
                      'iconLike': 'heart',
                      'numberLike': '56',
                      'comments': 'chatbubble',
                      'numberCommnet': '65',
                      'isLike': false
    
                  },
                  {
                      'id': 2,
                      'category': 'TECHNOLOGY',
                      'subtitle': 'How might we help people become better-informed citizens?',
                      'iconLike': 'heart',
                      'numberLike': '56',
                      'comments': 'chatbubble',
                      'numberCommnet': '65',
                       'isLike': false
                  },
                  {
                      'id': 3,
                      'category': 'TECHNOLOGY',
                      'subtitle': 'How might we help people become better-informed citizens?',
                      'iconLike': 'heart',
                      'numberLike': '56',
                      'comments': 'chatbubble',
                      'numberCommnet': '65',
                       'isLike': false
                  },
                  {
                      'id': 4,
                      'category': 'TECHNOLOGY',
                      'subtitle': 'How might we help people become better-informed citizens?',
                      'iconLike': 'heart',
                      'numberLike': '56',
                      'comments': 'chatbubble',
                      'numberCommnet': '65',
                       'isLike': false
                  },
                  {
                      'id': 5,
                      'category': 'TECHNOLOGY',
                      'subtitle': 'How might we help people become better-informed citizens?',
                      'iconLike': 'heart',
                      'numberLike': '56',
                      'comments': 'chatbubble',
                      'numberCommnet': '65',
                       'isLike': false
                  },
                  {
                      'id': 6,
                      'category': 'TECHNOLOGY',
                      'subtitle': 'How might we help people become better-informed citizens?',
                      'iconLike': 'heart',
                      'numberLike': '56',
                      'comments': 'chatbubble',
                      'numberCommnet': '65',
                       'isLike': false
                  },
                  {
                      'id': 7,
                      'category': 'TECHNOLOGY',
                      'subtitle': 'How might we help people become better-informed citizens?',
                      'iconLike': 'heart',
                      'numberLike': '56',
                      'comments': 'chatbubble',
                      'numberCommnet': '65',
                       'isLike': false
                  },
                  {
                      'id': 8,
                      'category': 'TECHNOLOGY',
                      'subtitle': 'How might we help people become better-informed citizens?',
                      'iconLike': 'heart',
                      'numberLike': '56',
                      'comments': 'chatbubble',
                      'numberCommnet': '65',
                       'isLike': false
                  }
              ]
          };
        }

    //* Data Set for page 6
    getDataForLayout6 = (): any => {
        return {
            'toolbarTitle' : 'eCommerce - Category',
            'items': [
                {
                    'id': 1,
                    'image': 'assets/imgs/background/shop-1.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 2,
                    'image': 'assets/imgs/background/shop-2.jpg',
                    'title': 'Macbook Air',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 3,
                    'image': 'assets/imgs/background/shop-3.jpg',
                    'title': 'Macbook Air',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 4,
                    'image': 'assets/imgs/background/shop-4.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 5,
                    'image': 'assets/imgs/background/shop-5.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 6,
                    'image': 'assets/imgs/background/shop-6.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 7,
                    'image': 'assets/imgs/background/shop-7.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 8,
                    'image': 'assets/imgs/background/shop-1.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 9,
                    'image': 'assets/imgs/background/shop-2.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 10,
                    'image': 'assets/imgs/background/shop-5.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                }
            ]
        };
    }
     //* Data Set for page 7
     getDataForLayout7  = (): any => {
        return {
            'toolbarTitle' : ' eCommerce - Image Card',
            'items': [
                {
                    'id': 1,
                    'image': 'assets/imgs/background/1.jpg',
                    'title': 'Ice Cream',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 2,
                    'image': 'assets/imgs/background/2.jpg',
                    'title': 'Fruits',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 3,
                    'image': 'assets/imgs/background/3.jpg',
                    'title': 'Plastic Glass',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 4,
                    'image': 'assets/imgs/background/6.jpg',
                    'title': 'Cosmetics',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 5,
                    'image': 'assets/imgs/background/10.jpg',
                    'title': 'Donuts',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 6,
                    'image': 'assets/imgs/background/1.jpg',
                    'title': 'Ice Cream',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 7,
                    'image': 'assets/imgs/background/2.jpg',
                    'title': 'Fruits',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 8,
                    'image': 'assets/imgs/background/6.jpg',
                    'title': 'Cosmetics',
                    'numberProducts': '123 Products'
                }
            ]
        };
    }
    
        
            
            
    //* Data Set for page 8
    getDataForLayout8 = (): any => {
        return {
            'toolbarTitle' : 'eCommerce - Shopping Cart Card',
            'items': [
                {
                    'id': 1,
                    'image': 'assets/imgs/background/shop-1.jpg',
                    'title': 'Nikon DF Retro',
                    'price': 895.65,
                    'count': 1
                },
                {
                    'id': 2,
                    'image': 'assets/imgs/background/shop-2.jpg',
                    'title': 'Macbook Air',
                    'price': 895.65,
                    'count': 1
                },
                {
                    'id': 3,
                    'image': 'assets/imgs/background/shop-3.jpg',
                    'title': 'Macbook Air',
                    'price': 895.65,
                    'count': 1
                },
                {
                    'id': 4,
                    'image': 'assets/imgs/background/shop-4.jpg',
                    'title': 'Nikon DF Retro',
                    'price': 895.65,
                    'count': 1
                },
                {
                    'id': 5,
                    'image': 'assets/imgs/background/shop-5.jpg',
                    'title': 'Nikon DF Retro',
                    'price': 895.65,
                    'count': 1
                },
                {
                    'id': 6,
                    'image': 'assets/imgs/background/shop-6.jpg',
                    'title': 'Nikon DF Retro',
                    'price': 895.65,
                    'count': 1
                }
            ]
        };
    }

    //* Data Set for page 9
    getDataForLayout9 = (): any => {
        return {
            'toolbarTitle' : 'eCommerce - Thumb',
            'items': [
                {
                    'id': 1,
                    'title': 'Nikon Df DSLR',
                    'subtitle': 'Camera with 50mm',
                    'description': 'f/1.8 Lens (Silver)',
                    'image': 'assets/imgs/background/shop-2.jpg'
                },
                {
                    'id': 2,
                    'title': 'Audio-Technica',
                    'subtitle': 'BPHS1 Broadcast',
                    'description': 'Stereo Headset',
                    'image': 'assets/imgs/background/shop-5.jpg'
                },
                {
                    'id': 3,
                    'title': 'Apple 10.5" iPad Air)',
                    'subtitle': 'Early 2019, 64GB',
                    'description': 'Wi-Fi Only, SpaceG',
                    'image': 'assets/imgs/background/shop-7.jpg'
                },
                {
                    'id': 4,
                    'title': 'Nikon Df DSLR',
                    'subtitle': 'Camera with 50mm',
                    'description': 'f/1.8 Lens (Silver)',
                    'image': 'assets/imgs/background/shop-2.jpg'
                },
                {
                    'id': 5,
                    'title': 'Audio-Technica',
                    'subtitle': 'BPHS1 Broadcast',
                    'description': 'Stereo Headset',
                    'image': 'assets/imgs/background/shop-1.jpg'
                },
                {
                    'id': 6,
                    'title': 'Apple 10.5" iPad Air)',
                    'subtitle': 'Early 2019, 64GB',
                    'description': 'Wi-Fi Only, SpaceG',
                    'image': 'assets/imgs/background/shop-7.jpg'
                },
                {
                    'id': 7,
                    'title': 'Audio-Technica',
                    'subtitle': 'BPHS1 Broadcast',
                    'description': 'Stereo Headset',
                    'image': 'assets/imgs/background/shop-6.jpg'
                },
                {
                    'id': 8,
                    'title': 'Nikon Df DSLR',
                    'subtitle': 'Camera with 50mm',
                    'description': 'f/1.8 Lens (Silver)',
                    'image': 'assets/imgs/background/shop-2.jpg'
                }
            ]
        };
    }

     //* Data Set for page 10
     getDataForLayout10 = (): any => {
        return {
            'toolbarTitle' : 'eCommerce - Simple',
            'items': [
                {
                    'id': 1,
                    'iconLike': 'heart',
                    'image': 'assets/imgs/background/20.png',
                    'title': 'Nikon Coolpix',
                    'price': '$ 989.89',
                    'btButton': 'Purchase',
                    'isLike': false
                },
                {
                    'id': 2,
                    'iconLike': 'heart',
                    'image': 'assets/imgs/background/21.png',
                    'title': 'Photo Camera',
                    'price': '$ 895.65',
                    'btButton': 'Purchase',
                    'isLike': false
                },
                {
                    'id': 3,
                    'iconLike': 'heart',
                    'image': 'assets/imgs/background/22.png',
                    'title': 'Mavic Camera Drone',
                    'price': '$ 895.65',
                    'btButton': 'Purchase',
                    'isLike': false
                },
                {
                    'id': 4,
                    'iconLike': 'heart',
                    'image': 'assets/imgs/background/23.png',
                    'title': 'Macbook Air',
                    'price': '$ 895.65',
                    'btButton': 'Purchase',
                    'isLike': false
                },
                {
                    'id': 5,
                    'iconLike': 'heart',
                    'image': 'assets/imgs/background/20.png',
                    'title': 'Nikon Coolpix',
                    'price': '$ 895.65',
                    'btButton': 'Purchase',
                    'isLike': false
                },
                {
                    'id': 6,
                    'iconLike': 'heart',
                    'image': 'assets/imgs/background/21.png',
                    'title': 'Photo Camera',
                    'price': '$ 895.65',
                    'btButton': 'Purchase',
                    'isLike': false
                }
            ]
        };
    }

    getDataForTheme = (menuItem: any): Array<any> => {
        return this[
            'getDataFor' +
            menuItem.theme.charAt(0).toUpperCase() +
            menuItem.theme.slice(1)
        ]();
    }

    load(item: any): Observable<any> {
        const that = this;
        that.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('listView/googleCards/' + item.theme)
                    .valueChanges()
                    .subscribe(snapshot => {
                        that.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        that.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                that.loadingService.hide();
                observer.next(this.getDataForTheme(item));
                observer.complete();
            });
        }
    }
}
