import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss']
})
export class BooleanComponent implements OnInit {

  field: BooleanInput;
  isSelected:any;
  group: FormGroup;
  value = null;
  initialized:boolean = false;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    if(!this.initialized){
      let value = this.group.get(this.field.name).value;
      if(value == false) {
        this.isSelected = this.field.labelFalse;
      } else if(value == true) {
        this.isSelected = this.field.labelTrue;
      }
      this.initialized = true;
    }
    this.cdr.detectChanges();
  }
 
  ngOnInit() {
    this.group.get(this.field.name).valueChanges.subscribe(res => {
      if(res == false) {
        this.isSelected = this.field.labelFalse;
      } else {
        this.isSelected = this.field.labelTrue;
      }
      this.initialized = true;
    })
  }

  setIsSelected(selected,value){
    if(!this.field.enableIfResult){
      return false
    }
    this.isSelected = selected
    this.group.get(`${this.field.name}`).setValue(value)
  }
}
