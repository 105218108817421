import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IonRadio } from '@ionic/angular';

@Component({
  selector: 'app-radiogroup',
  templateUrl: './radiogroup.component.html',
  styleUrls: ['./radiogroup.component.scss'],
})
export class RadiogroupComponent implements OnInit {

  field: RadioGroupInput;

  group: FormGroup;
  value = null;

  constructor() { }

  ngOnInit() { 
    this.group.get(this.field.name).valueChanges.subscribe(res => {
      this.value = res;
    })
   }

  change(name) {
    this.group.get(this.field.name).setValue(name);
  }

}
