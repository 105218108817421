import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
})
export class NewUserComponent implements OnInit {

  isInsured: boolean = true;
  parent: number = 0;
  broker: number = 1;
  others: number = 2;
  relationship: number = null;
  isPasswordVisible: boolean;

  constructor(private navCtrl: NavController) { }

  ngOnInit() { }

  insuredChange() {
    this.isInsured = !this.isInsured;
    this.relationship = null;
  }

  relationshipChange(val: any) {
    this.relationship = val;
  }

  getPasswordType() {
    return this.isPasswordVisible ? 'text' : 'password'
  }

  changeVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible
  }

  getIconByType() {
    return this.isPasswordVisible ? 'eye' : 'eye-off'
  }

  toDashboard() {
    this.navCtrl.navigateForward('dashboard');
  }

}
