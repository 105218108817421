import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  value:number = 0
  field: TextInput;
  group: FormGroup;

  constructor() { 
  }

  ngOnInit() {
    this.value = this.field.minLength ? this.field.minLength : 0
    this.group.get(this.field.name).valueChanges.subscribe((value)=>{
      this.value=value
    })
   }

   changeDateFormat() {
     const value = this.group.get(this.field.name).value;

     this.group.get(this.field.name).setValue(value.split('T')[0])
   }

}
