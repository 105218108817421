import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class IntroService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

    // Set data for - WIZARD MAIN
    getData = (): any => {
        return {
            'toolbarTitle': '',
            'hasButtons': false,
            'btnPrev': 'Previous',
            'btnNext': 'Next',
            'btnFinish': 'Finish',
            'items': [
                {
                    'image': 'assets/imgs/background/1.png',
                    'title': 'Bem-vindo(a)!',
                    'description': `Veja como é fácil utiliza o app para fazer auto-vistoria:`,
                    'span': 'EasyInspect',
                    'content': {
                        'title': 'Cadastre suas',
                        'description': 'informações no app',
                        'span': 'EasyInspect'
                    },
                    'button': false
                },
                {
                    'image': 'assets/imgs/background/1.png',
                    'title': '',
                    'description': ``,
                    'span': '',
                    'content': {
                        'title': 'Com o celular em mãos,',
                        'description': 'cadastre o(s) equipamentos(s)',
                        'span': 'para vistoria no nosso sistema'
                    },
                    'button': false
                },
                {
                    'image': 'assets/imgs/background/1.png',
                    'title': '',
                    'description': ``,
                    'span': '',
                    'content': {
                        'title': 'Capture a assinatura do',
                        'description': 'responsável pela vistoria',
                        'span': ''
                    },
                    'button': false
                },
                {
                    'image': 'assets/imgs/background/1.png',
                    'title': '',
                    'description': ``,
                    'span': '',
                    'content': {
                        title: 'Fotografe detalhadamente os itens para que possamos',
                        description: 'ter todas as informações necessárias',
                        span: ''
                    },
                    'button': false
                },
                {
                    'image': 'assets/imgs/background/1.png',
                    'title': '',
                    'description': ``,
                    'span': '',
                    'content': {
                        title: 'As informações serão',
                        description: 'enviadas de modo seguro,',
                        span: 'fácil e organizado'
                    },
                    'button': "Vamos começar!"
                },
            ]
        };
    }

    load(): Observable<any> {
        const that = this;
        that.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('intro')
                    .valueChanges()
                    .subscribe(snapshot => {
                        that.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        that.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                that.loadingService.hide();
                observer.next(this.getData());
                observer.complete();
            });
        }
    }
}
