import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable()
export class NewPasswordService implements IService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

    getTitle = (): string => 'Old/New Passworsd pages';

    getAllThemes = (): Array<any> => {
        return [
          {'url': 'new-password/0', 'title' : 'New Password With Background', 'theme'  : 'layout1'}
        ];
    }

    getDataForTheme = (menuItem: any): Array<any> => {
        return this[
            'getDataFor' +
            menuItem.theme.charAt(0).toUpperCase() +
            menuItem.theme.slice(1)
        ]();
    }

    //* Data Set for page 1
    getDataForLayout1 = (): any => {
      return {
        'toolbarTitle'     : 'New Password With Background',
        'title'            : 'Forgot Password',
        'subtitle'         : 'Molecule UI Kit',
        'background'       : 'assets/imgs/background/29.jpg',
        'newpassword'      : 'New password',
        'oldpassword'      : 'Repeat Password',
        'signuptext'       :'Don’t have an account',
        'signup'           :'Signup',
        'reset'            : 'Reset Password',
        'logo'             : 'assets/imgs/logo/login.png',
      };
    }

    load(item: any): Observable<any> {
        this.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('newPassword/' + item.theme)
                    .valueChanges()
                    .subscribe(snapshot => {
                        this.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        this.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                this.loadingService.hide();
                observer.next(this.getDataForTheme(item));
                observer.complete();
            });
        }
    }
}
