import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-pie',
  templateUrl: './progress-pie.component.html',
  styleUrls: ['./progress-pie.component.scss'],
})
export class ProgressPieComponent implements  OnChanges {

  @Input() percentage: number | string = 0;
  @Input() classPercentage:string = `p${this.percentage}`;

  constructor() { }

 
  ngOnChanges(){
    this.classPercentage = `p${this.percentage}`;
  }

}
