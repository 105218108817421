import { Component, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'splash-screen-layout-1',
  templateUrl: 'splash-screen-layout-1.page.html',
  styleUrls: ['splash-screen-layout-1.page.scss'],
})
export class SplashScreenLayout1Page implements OnChanges, OnDestroy {
  @Input() data: any;
  @Output() onRedirect = new EventEmitter();

  timer: any;

  constructor() { }

  ngOnChanges(changes: { [propKey: string]: any }) {
    clearInterval(this.timer);
    this.data = changes['data'].currentValue;
    this.executeEvents();
  }

  executeEvents(): void {
    const obs$ = (this.data.emitRedirect$ as Observable<any>)
    obs$.subscribe(emit => {
      if(emit) {
        this.onRedirect.emit();
      }
    })
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
