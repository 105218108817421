import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class ParallaxService implements IService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

    getTitle = (): string => 'Parallax';

    getAllThemes = (): Array<any> => {
        return [
            { 'url': 'parallax/0', 'title': 'Product', 'theme': 'layout1' },
            { 'url': 'parallax/1', 'title': 'Shopping', 'theme': 'layout2' },
            { 'url': 'parallax/2', 'title': 'Profile', 'theme': 'layout3' }
        ];
    }

    getDataForTheme = (menuItem: any): any => {
        return this[
            'getDataFor' +
            menuItem.theme.charAt(0).toUpperCase() +
            menuItem.theme.slice(1)
        ]();
    }

    //* Data Set for page 1
    getDataForLayout1 = (): any => {
        return {
            'toolbarTitle': 'Product',
            'imge': 'assets/imgs/background/19.png',
            'title': 'Nikon Df DSLR Camera with 50mm f/1.8 Lens (Silver)',
            'description': `Whether raising your game to SLR level photography or having fun with a feature-rich, versatile SLR you can use pretty much anywhere, the EOS 80D camera is your.`,
            'price': '$1,195.00',
            'instock': 'In Stock',
            'button': 'Add to Cart',
            'items': [
                {
                    'id': 1,
                    'image': 'assets/imgs/background/shop-1.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 2,
                    'image': 'assets/imgs/background/shop-2.jpg',
                    'title': 'Macbook Air',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 3,
                    'image': 'assets/imgs/background/shop-3.jpg',
                    'title': 'Macbook Air',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 4,
                    'image': 'assets/imgs/background/shop-4.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 5,
                    'image': 'assets/imgs/background/shop-5.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 6,
                    'image': 'assets/imgs/background/shop-6.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 7,
                    'image': 'assets/imgs/background/shop-7.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 8,
                    'image': 'assets/imgs/background/shop-1.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 9,
                    'image': 'assets/imgs/background/shop-2.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                },
                {
                    'id': 10,
                    'image': 'assets/imgs/background/shop-5.jpg',
                    'title': 'Nikon DF Retro',
                    'numberProducts': '123 Products'
                }
            ]
        };
    }

    //* Data Set for page 2
    getDataForLayout2 = (): any => {
        return {
            'toolbarTitle': 'Shopping',
            'headerImage': 'assets/imgs/background/18.jpg',
            'toolBarTitle': 'Product',
            'title': 'Shopping',
            'items': [
                {
                    'id': 1,
                    'title': 'Nikon Df DSLR',
                    'subtitle': 'Camera with 50mm',
                    'description': 'f/1.8 Lens (Silver)',
                    'image': 'assets/imgs/background/shop-2.jpg'
                },
                {
                    'id': 2,
                    'title': 'Audio-Technica',
                    'subtitle': 'BPHS1 Broadcast',
                    'description': 'Stereo Headset',
                    'image': 'assets/imgs/background/shop-5.jpg'
                },
                {
                    'id': 3,
                    'title': 'Apple 10.5" iPad Air)',
                    'subtitle': 'Early 2019, 64GB',
                    'description': 'Wi-Fi Only, SpaceG',
                    'image': 'assets/imgs/background/shop-7.jpg'
                },
                {
                    'id': 4,
                    'title': 'Nikon Df DSLR',
                    'subtitle': 'Camera with 50mm',
                    'description': 'f/1.8 Lens (Silver)',
                    'image': 'assets/imgs/background/shop-2.jpg'
                },
                {
                    'id': 5,
                    'title': 'Audio-Technica',
                    'subtitle': 'BPHS1 Broadcast',
                    'description': 'Stereo Headset',
                    'image': 'assets/imgs/background/shop-1.jpg'
                },
                {
                    'id': 6,
                    'title': 'Apple 10.5" iPad Air)',
                    'subtitle': 'Early 2019, 64GB',
                    'description': 'Wi-Fi Only, SpaceG',
                    'image': 'assets/imgs/background/shop-7.jpg'
                },
                {
                    'id': 7,
                    'title': 'Audio-Technica',
                    'subtitle': 'BPHS1 Broadcast',
                    'description': 'Stereo Headset',
                    'image': 'assets/imgs/background/shop-6.jpg'
                },
                {
                    'id': 8,
                    'title': 'Nikon Df DSLR',
                    'subtitle': 'Camera with 50mm',
                    'description': 'f/1.8 Lens (Silver)',
                    'image': 'assets/imgs/background/shop-2.jpg'
                }
            ]
        };
    }

    //* Data Set for page 3
    getDataForLayout3 = (): any => {
        return {
            'toolbarTitle': 'Profile',
            'avatar': 'assets/imgs/avatar/0.jpg',
            'title': 'Tiffany Pearson',
            'subtitle': 'Product Designer',
            'follow': 'Follow',
            'message': 'Message',
            'items': [
                {
                    'id': 1,
                    'category': 'ART & DESIGN',
                    'title': 'A New Design Model That Helps People Change Their Lives',
                    'image': 'assets/imgs/background/4.jpg',
                    'iconLike': 'heart',
                    'iconComments': 'chatbubble',
                    'likeText': '945',
                    'commentsText': '77'
                },
                {
                    'id': 2,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/1.jpg',
                    'iconLike': 'heart',
                    'iconComments': 'chatbubble',
                    'likeText': '945',
                    'commentsText': '77'
                },
                {
                    'id': 3,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/2.jpg',
                    'iconLike': 'heart',
                    'iconComments': 'chatbubble',
                    'likeText': '945',
                    'commentsText': '77'
                },
                {
                    'id': 4,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/3.jpg',
                    'iconLike': 'heart',
                    'iconComments': 'chatbubble',
                    'likeText': '945',
                    'commentsText': '77'
                },
                {
                    'id': 5,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/4.jpg',
                    'iconLike': 'heart',
                    'iconComments': 'chatbubble',
                    'likeText': '945',
                    'commentsText': '77'
                },
                {
                    'id': 6,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/5.jpg',
                    'iconLike': 'heart',
                    'iconComments': 'chatbubble',
                    'likeText': '945',
                    'commentsText': '77'
                }
            ]
        };
    }

    load(item: any): Observable<any> {
        this.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('parallax/' + item.theme)
                    .valueChanges()
                    .subscribe(snapshot => {
                        this.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        this.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                this.loadingService.hide();
                observer.next(this.getDataForTheme(item));
                observer.complete();
            });
        }
    }
}
