import { Injectable } from '@angular/core';
import {
  Plugins, CameraResultType, CameraSource, CameraPhoto
} from '@capacitor/core';
import { from, Observable } from 'rxjs';

const { Camera, Filesystem, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor() { }

  convertFileToBase64(file: any): Observable<string | ArrayBuffer> {
    return new Observable<string | ArrayBuffer>(subscriber => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => { subscriber.next(reader.result); subscriber.complete() };
      reader.onerror = () => subscriber.error(reader.error);
      return () => reader.abort();
    })
  }
}
