import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { ContactModalComponent } from 'src/app/shared/components/contact-modal/contact-modal.component';


@Component({
  selector: 'app-vistory-not-found',
  templateUrl: './vistory-not-found.component.html',
  styleUrls: ['./vistory-not-found.component.scss'],
})
export class VistoryNotFound implements OnInit {
  
  email: string
  phone: string;
  constructor(private navctrl: NavController,private modalController: ModalController) { }

  ngOnInit() { 
    this.email = JSON.parse(localStorage.getItem('theme'))?.contact?.email ?? '';
    this.phone = JSON.parse(localStorage.getItem('theme'))?.contact?.phone ?? '';
    
  }
  
  
  
  async showModal() {
    const modal = await this.modalController.create({
      component: ContactModalComponent,
      cssClass: 'modal-custom-contact',
    })

    return await modal.present();
  }


}
