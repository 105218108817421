import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-tile',
  templateUrl: './pending-tile.component.html',
  styleUrls: ['./pending-tile.component.scss'],
})
export class PendingTileComponent implements OnInit {

  @Input() pendingDescription: string = ''

  showAccordion: boolean = false;

  constructor() { }

  ngOnInit() {}


  toggleAccordion() {
    this.showAccordion = !this.showAccordion
  }

}
