import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreService } from 'src/app/shared/services/core.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private coreService: CoreService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.coreService.getCurrentInspectionData != undefined && this.coreService.inspectionUniqueId.value != undefined && this.coreService.getCurrentInspectionData.JsonAnalysis == null) {
      localStorage.removeItem('pending-map');
      return true;
    } else if (this.coreService.getCurrentInspectionData.JsonAnalysis != null && (this.checkRouteWhenPending(route) || this.checkRouteEquipmentPending(route))) {
      return true
    }
    if(route.params?.anotherPath) {
      return true;
      console.log(`${route.params.uniqueId}/${route.params?.anotherPath}`)
      this.coreService.navCtrl.navigateForward(`${route.params.uniqueId}/${route.params?.anotherPath}`);
    } else {
      this.coreService.navCtrl.navigateForward(`${route.params.uniqueId}`);
    }
    return false;
  }

  private checkRouteWhenPending(route: ActivatedRouteSnapshot) {
    const pendingPath = route.url[1].path;
    const videoSignaturePath = route?.url[1]?.path;
    const addressPath = route?.children[0]?.children[0]?.url[0]?.path;
    const addressAuto = route?.children[0]?.url[0]?.path;
    const success = route.url[1].path;
    return pendingPath == 'pending' || videoSignaturePath == 'video-signature' || addressPath == 'address-manually' || success == 'vistory-complete-successfull' || addressAuto == 'get-address'|| addressAuto == 'test-video' ? true : false
  }

  private checkRouteEquipmentPending(route: ActivatedRouteSnapshot) {
    const pathEquipmentId = route?.children[0]?.children[0]?.url[1]?.path
    const pendingMap: Map<any, any> = this.coreService.mountPendingMap();
    return pendingMap.has(pathEquipmentId);
  }

}
