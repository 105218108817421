// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //glcaimsAPI: 'http://192.168.0.12:8087',
  // glcaimsAPI: 'https://gclaims-bpo.wizsolucoes.com.br/v2',
  glcaimsAPI: 'https://gclaims-bpo-hml.wizsolucoes.com.br/v2',
  firebaseConfig: {
    apiKey: "AIzaSyAEEqstjrV8zbU0NYWYB89_a6_ge-hLnxo",
    authDomain: "easyinspect-wiz.firebaseapp.com",
    projectId: "easyinspect-wiz",
    storageBucket: "easyinspect-wiz.appspot.com",
    messagingSenderId: "1035896955474",
    appId: "1:1035896955474:web:f712916b008bc1c8c9758b",
    measurementId: "G-Z10R595HHQ"
  }
};

//  firebaseConfig: {
//   apiKey: "AIzaSyAek95fJCfkNA4a3dKWY45BtQA0drt3Sjk",
//   authDomain: "easyinspect-f537c.firebaseapp.com",
//   projectId: "easyinspect-f537c",
//   storageBucket: "easyinspect-f537c.appspot.com",
//   messagingSenderId: "308538135441",
//   appId: "1:308538135441:web:e8a424358a45e70a85c030"
// }

// firebaseConfig: {
//   apiKey: "AIzaSyDicl21DHIGJam0ekjdtMsj6zWnnzMqMRM",
//   authDomain: "easyinspect-b3a86.firebaseapp.com",
//   projectId: "easyinspect-b3a86",
//   storageBucket: "easyinspect-b3a86.appspot.com",
//   messagingSenderId: "91954177038",
//   appId: "1:91954177038:web:416e67c77c73c511a2085f",
//   measurementId: "G-B9S5X0B1Y1"
// }




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
