import { AfterContentChecked, AfterViewChecked, AfterViewInit, Directive, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DynamicLogicService } from '../services/dynamicLogic/dynamic-logic.service';
import { FormService } from '../services/form/form.service';


@Directive({
  selector: '[DirectiveDynamicForm]'
})
export class DirectiveDynamicFormDirective implements OnInit {
  @Input() group: FormGroup;
  @Input() field: any;

  constructor(private dynamicLogic: DynamicLogicService, private fs: FormService) { }

  ngOnInit(): void {
    this.group.valueChanges.subscribe((value) => {
      if (this.field.visibleIf) {
        if (this.field.visibleIf.includes('or') || this.field.visibleIf.includes('and')) {
          this.field.visibleIfResult = this.logicOrAnd(this.field.visibleIf)
        } else {
          //Alterado para diferente de undefined, pois quando o valor é boolean e é = false, não caia na validação.
          if (this.group.get(this.field.visibleIf[0])?.value != undefined)
            this.field.visibleIfResult = this.dynamicLogic.checkExpression(this.group.get(this.field.visibleIf[0]).value, this.field.visibleIf)
        }
      }

      if (this.field.requiredIf) {

        if (this.field.requiredIf.includes('or') || this.field.requiredIf.includes('and')) {

          this.field.requiredIfResult = this.logicOrAnd(this.field.requiredIf)


        } else {
          if (this.group.get(this.field.requiredIf[0])?.value)
            this.field.requiredIfResult = this.dynamicLogic.checkExpression(this.group.get(this.field.requiredIf[0]).value, this.field.requiredIf);
        }
        if (this.field.requiredIfResult) {
          if (this.field?.validators?.length > 0) {
            const validatorsList = this.fs.bindValidations(this.field.validators, this.field.requiredIfResult)
            this.group.get(this.field.name).setValidators(validatorsList);

          } else {
            this.group.get(this.field.name).setValidators(Validators.required);
          }
        } else if (this.field.requiredIfResult === false) {
          this.group.get(this.field.name).clearValidators();
        }


      }
      if (this.field.enableIf) {

        if (this.field.enableIf.includes('or') || this.field.enableIf.includes('and')) {
          this.field.enableIfResult = this.logicOrAnd(this.field.enableIf)
        } else {
            this.field.enableIfResult = this.dynamicLogic.checkExpression(this.group.get(this.field.enableIf[0]).value, this.field.enableIf)
        }

        if (!this.field.enableIfResult) {
          this.group.get(this.field.name).disable({ emitEvent: false });
        } else {
          this.group.get(this.field.name).enable({ emitEvent: false })
        }
      }
    })
  }
  logicOrAnd(expression) {
    let conditions = []
    let splitExpression = []
    let resultArray = []
    let buildExpression = []

    if (this.group.get(expression[0])?.value) {
      expression.forEach((el, cont = 0) => {
        if (el == "and" || el == "or") {

          resultArray.push(this.dynamicLogic.checkExpression(this.group.get(splitExpression[0]).value, splitExpression))
          conditions.push(el == "and" ? "&&" : "||")
          splitExpression = []

        } else {
          splitExpression.push(el)
          if (cont == expression.length - 1) {
            resultArray.push(this.dynamicLogic.checkExpression(this.group.get(splitExpression[0]).value, splitExpression))
          }
        }
        cont++
      });
    }

    let indexConditions = 0
    let indexResultArray = 0
    for (let index = 0; index < resultArray.length + conditions.length; index++) {

      if (index == 0) {
        buildExpression.push(resultArray[index])
        indexResultArray++

      } else if (index % 2 == 0) {
        buildExpression.push(resultArray[indexResultArray])
        indexResultArray++
      }
      else {
        buildExpression.push(conditions[indexConditions])
        indexConditions++
      }
    }

    return eval(buildExpression.join(' '))
  }



}