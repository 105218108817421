import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, forwardRef, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { InspectionService } from 'src/app/core/services/inspection.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { DynamicLogicService } from 'src/app/shared/services/dynamicLogic/dynamic-logic.service';
import { FormService } from 'src/app/shared/services/form/form.service';
import { InvalidModalComponent } from '../../invalid-modal/invalid-modal.component';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFormComponent),
      multi: true
    }
  ],
  //encapsulation: ViewEncapsulation.None
})
export class DynamicFormComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  id: any
  currentPage: any = 0
  loadNextButton: boolean = false
  pagesDecoded: any
  @Input() fields: any = [];
  @Input() pages: any = [];
  @Input() values: any = null;
  @Input() inspectionData: any = null;
  enableForm: boolean = true;
  lastFormValue: any = {};
  @Input() form: FormGroup = undefined;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  camPermission: any;

  get value() {
    return this.form.value;
  }

  constructor(
    private modalController: ModalController,
    private dynamicLogic: DynamicLogicService,
    private fb: FormBuilder
    , private fs: FormService,
    private coreService: CoreService,
    private loaderService: LoaderService,
    private inspectionService: InspectionService
  ) { }

  ngAfterViewInit(): void {
    if (this.values != null) {
      this.form.patchValue(this.values, { emitEvent: true });
    }
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(value: any): void {
    //this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    this.camPermission = JSON.parse(localStorage.getItem('camPermission'))
    if (this.form) {
      this.lastFormValue = this.form.value;
      this.createControl(this.form);

      if (this.lastFormValue && Object.keys(this.lastFormValue).length > 0) {
        this.form.setValue(this.lastFormValue);
      }

    } else {
      this.form = this.createControl();
    }

    this.form.valueChanges.subscribe(selectedValue => {
      if (this.onChange)
        this.onChange(this.value);
    });

    this.fs.currentPageBehavior.subscribe((value) => {
      if (this.currentPage != 0 && value != this.currentPage) {
        this.currentPage = parseInt(value)
        this.form = this.createControl()
        setTimeout(() => {
          this.form.patchValue(this.values, { emitEvent: true });
        }, 500);
      }
    })
  }

  convertObjectToFormGroup(json) {
    if (!json)
      return json;
    Object.keys(json).forEach(function (key) {
      if (Array.isArray(json[key])) {
        json[key] = this.fb.array(json[key]);
      }
      else if (typeof json[key] == 'object') {
        json[key] = this.convertObjectToFormGroup(json[key]);
      }
    }.bind(this));

    return json;
  }

  ///Foi criado o formByRef, por causa do panel-dynamic
  ///No panel-dynamic, ele cria o formGroup e passa como referencia para o dynamic-form, via parametro. 
  ///Nessa situação o createControl cria os componentes no formGroup do parent que é passado.
  createControl(formByRef: any = null) {
    if (!formByRef)
      // this.clearControls()

      if ((this.pages || []).length > 0) {
        this.pagesDecoded = JSON.parse(this.pages).pages;

        this.fields = this.fs.loadPage(this.currentPage, this.pagesDecoded)
      }

    if (!this.lastFormValue)
      this.lastFormValue = {};
    const group = this.fb.group({});
    if (Object.keys(this.lastFormValue).length) {
      Object.keys(this.lastFormValue).forEach(el => {
        const control = this.fb.control(null);
        group.addControl(el, control)
      })
    }
    group.setValue(this.lastFormValue);

    if (!this.fields)
      this.fields = [];

    this.fields.forEach(field => {
      field.enableIfResult = true
      field.visibleIfResult = true
      field.requiredIfResult = false
      if (field.hasOwnProperty("requiredIf")) {
        field.requiredIf = field.requiredIf.replaceAll('{', '')
        field.requiredIf = field.requiredIf.replaceAll('}', '')
        field.requiredIf = field.requiredIf.replaceAll("'", '')

        field.requiredIf = field.requiredIf.split(" ")
      }
      if (field.hasOwnProperty("enableIf")) {

        field.enableIf = field.enableIf.replaceAll('{', '')
        field.enableIf = field.enableIf.replaceAll('}', '')
        field.enableIf = field.enableIf.replaceAll("'", '')

        field.enableIf = field.enableIf.split(" ")
        field.enableIfResult = false
      }

      if (field.hasOwnProperty("visibleIf")) {
        //No caso do visibleIf dentro do Panel, geralmente as condições são formadas assim: panel.property; Isso quebra essa engine.
        if (field.visibleIf.indexOf(".") > -1) {
          field.visibleIf = field.visibleIf.split(".")[1];
        }

        if (field.visibleIf.replaceAll) {
          field.visibleIf = field.visibleIf.replaceAll('{', '')
          field.visibleIf = field.visibleIf.replaceAll('}', '')
          field.visibleIf = field.visibleIf.replaceAll("'", '')
        } else {

        }

        if (field.visibleIf.split) {

          field.visibleIf = field.visibleIf.split(" ")
        } else {

        }
        field.visibleIfResult = false
      }

      const control = this.fb.control(null, this.fs.bindValidations(field.validators || [], field.isRequired));
      if (formByRef)
        formByRef.addControl(field.name, control);
      else
        group.addControl(field.name, control);
      /**if(field.type=="paneldynamic"){
        group.addControl(field.name, this.fb.array([]));
      }else{
        const control = this.fb.control(null, this.fs.bindValidations(field.validators || [], field.isRequired));
        group.addControl(field.name, control);
      }**/

      if (!this.loadNextButton && field.type == 'submit') {
        this.loadNextButton = field.type == 'submit'
      }
    });
    return group;
  }


  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.submit.emit(this.form);
      this.decideNextPage();
    } else {
      this.validateAllFormFields(this.form);
      this.showModal()
    }

  }

  async showModal() {
    const modal = await this.modalController.create({
      component: InvalidModalComponent,
      cssClass: 'modal-custom-invalid',
    })

    return await modal.present();
  }

  decideNextPage() {
    if ((this.pagesDecoded || []).length > 1 && this.currentPage < (this.pagesDecoded || []).length - 1) {
      this.enableForm = false;
      this.lastFormValue = this.form.value;
      this.currentPage += 1;
      let page = this.pagesDecoded[this.currentPage];
      if (page.hasOwnProperty("visibleIf")) {
        //No caso do visibleIf dentro do Panel, geralmente as condições são formadas assim: panel.property; Isso quebra essa engine.
        if (page.visibleIf.indexOf(".") > -1) {
          page.visibleIf = page.visibleIf.split(".")[1];
        }
        page.visibleIf = page.visibleIf.replaceAll('{', '')
        page.visibleIf = page.visibleIf.replaceAll('}', '')
        page.visibleIf = page.visibleIf.replaceAll("'", '')
        page.visibleIf = page.visibleIf.split(" ")

        //Se não for permitido visualizar a pagina, vamos avançar para a proxima, automaticamente.
        if (!this.logicOrAnd(this.lastFormValue, page.visibleIf)) {
          this.currentPage += 1
          return this.decideNextPage();
        }
      }
      this.form = this.createControl()
      this.enableForm = true;
      //Não entendi o motivo do timeout aqui. 
      //Mas esse cara fazia os proximos componentes não pegarem o valor atual das propriedades
      //Comentei esse cara.
      setTimeout(() => {
        this.form.patchValue(this.values, { emitEvent: true });
      }, 1000);
    } else {
      if (this.inspectionData?.interpolations?.length > 0) {
        this.generateInterpolations().then(() => {
          this.coreService.navigate('choose-method')
        })
      } else {
        this.coreService.navigate('choose-method')
      }
    }
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    })
  }

  private clearControls() {
    if (this.form) {
      if (this.form.controls) {
        for (const control in this.form.controls) {
          if (Object.prototype.hasOwnProperty.call(this.form.controls, control)) {
            this.form.removeControl(control);
          }
        }
      }
    }
  }


  private logicOrAnd(data, expression) {
    let conditions = []
    let splitExpression = []
    let resultArray = []
    let buildExpression = []
    if (data[expression[0]]) {
      expression.forEach((el, cont = 0) => {
        if (el == "and" || el == "or") {
          resultArray.push(this.dynamicLogic.checkExpression(data[splitExpression[0]], splitExpression))
          conditions.push(el == "and" ? "&&" : "||")
          splitExpression = []
        } else {
          splitExpression.push(el)
          if (cont == expression.length - 1) {
            resultArray.push(this.dynamicLogic.checkExpression(data[splitExpression[0]], splitExpression))
          }
        }
        cont++
      });
    }

    let indexConditions = 0
    let indexResultArray = 0
    for (let index = 0; index < resultArray.length + conditions.length; index++) {

      if (index == 0) {
        buildExpression.push(resultArray[index])
        indexResultArray++

      } else if (index % 2 == 0) {
        buildExpression.push(resultArray[indexResultArray])
        indexResultArray++
      }
      else {
        buildExpression.push(conditions[indexConditions])
        indexConditions++
      }
    }

    return eval(buildExpression.join(' '))
  }

  async generateInterpolations() {
    const json = this.form.getRawValue();
    for (const item of this.inspectionData.interpolations) {
      let result = '';
      const interpolation = item.Interpolation;
      try {
        eval(interpolation)
      } catch (error) {

      }
      item.result = result == undefined ? '' : result;
    }

    return this.inspectionService.updateInspection({
      interpolations: this.inspectionData.interpolations
    }).toPromise();
  }

}
