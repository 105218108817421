
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from "@angular/fire";
import { AngularFireStorageModule, BUCKET } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { ToastService } from './services/toast-service';
import { LoadingService } from './services/loading-service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { IonicStorageModule } from '@ionic/storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IntroEasyinspectComponent } from './pages/intro-easyinspect/intro-easyinspect.component';
import { CommonModule } from '@angular/common';
import { NewUserComponent } from './pages/new-user/new-user.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ViewDetailsComponent } from './pages/view-details/view-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderInterceptorService } from './core/helpers/loader-inteceptor.service';
import { SharedModule } from './shared/shared.module';
import { VistoryCompleteSuccessful } from './pages/vistory-complete-successfull/vistory-complete-successfull.component';
import { VistoryHasProblem } from './pages/vistory-has-problem/vistory-has-problem.component';
import { VideoSignature } from './pages/video-signature/video-signature.page';
import { VistoryNotFound } from './pages/vistory-not-found/vistory-not-found.component';
import { VistoryNotEnabledComponent } from './pages/vistory-not-enabled/vistory-not-enabled.component';
import { CoreService } from './shared/services/core.service';
import { InspectionService } from './core/services/inspection.service';
import { filter } from 'rxjs/internal/operators/filter';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { VistoryExpiratedComponent } from './pages/vistory-expirated/vistory-expirated.component';
import { InvalidLinkComponent } from './pages/invalid-link/invalid-link.component';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NotDeviceComponent } from './pages/not-device/not-device.component';
import { TestComponent } from './pages/test/test.component';
import { CustomThemeService } from './core/services/custom-theme.service';

@NgModule({
  declarations: [
    AppComponent,
    IntroEasyinspectComponent,
    NewUserComponent,
    DashboardComponent,
    ViewDetailsComponent,
    VistoryCompleteSuccessful,
    VistoryHasProblem,
    VideoSignature,
    VistoryNotFound,
    VistoryExpiratedComponent,
    VistoryNotEnabledComponent,
    InvalidLinkComponent,
    NotDeviceComponent,
    TestComponent
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    BrowserModule, HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, scope: './' }),
    NgbModule,
    SharedModule
  ],
  providers: [
    StatusBar,
    SplashScreen, ToastService, LoadingService, Keyboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: BUCKET, useValue: environment.firebaseConfig.storageBucket },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(private router: Router, private coreService: CoreService, private inspectionService: InspectionService, private route: ActivatedRoute, private readonly themeService: CustomThemeService) {
    if (this.coreService.getCurrentInspectionData != undefined) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      )
        .subscribe(event => {
          const index = String(event['url']).indexOf('/', 2)
          const url = String(event['url']).substring(index)
          if (url != `/${this.route.firstChild.snapshot.params.uniqueId}`
            && url != "/inspection/0" && url != '/' && url != '/vistory-not-found' && url != '/vistory-has-problem' && url != '/vistory-not-enabled' && url != '/vistory-expirated' && url != '/not-device') {
            this.inspectionService.updateInspection({ lastPageUrl: url })
          }
        });
    }
    this.themeService.getTheme();
  }

}
