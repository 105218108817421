import { AfterViewInit, Component, ViewChild, OnInit  } from '@angular/core';
import { FormGroup,FormArray,FormControl,FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { GclaimsService } from 'src/app/core/services/gclaims.service';
import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-requestdocuments',
  templateUrl: './requestdocuments.component.html',
  styleUrls: ['./requestdocuments.component.scss']
})
export class RequestDocumentsComponent implements OnInit, AfterViewInit {
  field: any;
  group: FormGroup;
  values: any = [];
  formsElements:any = [];
  inspectionData:any={};
  initialized:boolean = false;
  pendingDocuments:any=[];
  public boundedRefreshPendingDocumentsValue= this.refreshPendingDocumentsValue.bind(this);

  constructor(private fb: FormBuilder,
    private coreService: CoreService,private gClaimsService: GclaimsService) { 

  }  
  ngAfterViewInit(): void {
    this.inspectionData = this.coreService.getCurrentInspectionData;
    this.gClaimsService.getRequestDocuments(this.inspectionData.SurveyUniqueId).pipe(take(1))
    .subscribe(res => {
        let docs = res || [];

        let currentFilesInStore = this.group.get(this.field.name).value || [];
        if(currentFilesInStore.length > 0){

          docs.forEach(doc => {
             let idx = currentFilesInStore.findIndex((item)=>{
                return item.documentUniqueId == doc.UniqueId
             });
             if(idx > -1)
                doc.files = currentFilesInStore[idx].files;
          });
        }
        this.pendingDocuments=docs;
    });
  }

  ngOnInit() {
    
  }
  refreshPendingDocumentsValue(){

    var updatedModel = (this.pendingDocuments || []).map(pendingDocument=>{
      return {
        documentUniqueId:pendingDocument.UniqueId,
        files:pendingDocument.files
      };
    });
    if(this.field.allowMultiple) {
      this.group.get(this.field.name).setValue(updatedModel);  
    }
    this.group.get(this.field.name).setValue(updatedModel);
  }

}
