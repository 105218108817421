import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-matrixdropdown',
  templateUrl: './matrixdropdown.component.html',
  styleUrls: ['./matrixdropdown.component.scss'],
})
export class MatrixdropdownComponent implements OnInit {

  field: any;
  group: FormGroup;
  matrixGroup: FormGroup = new FormGroup({});

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.createControlsRows();
    this.createControlsColumns();
    this.setValue();
  }

  createControlsRows() {
    for (const row of this.field.rows) {
      this.matrixGroup.addControl(row, this.fb.group({}, []));
    }
  }

  createControlsColumns() {
    for (const row of this.field.rows) {
      for (const column of this.field.columns) {
        const group = this.matrixGroup.get(row) as FormGroup;
        group.addControl(column.name, this.fb.control(null))
      }
    }
  }

  setValue() {
    this.matrixGroup.valueChanges.subscribe(res => {
      this.group.get(this.field.name).setValue(this.matrixGroup.value)
    })
  }

}
