import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelpModalPage } from '../help-modal/help-modal.page';

@Component({
  selector: 'app-button-help',
  templateUrl: './button-help.component.html',
  styleUrls: ['./button-help.component.scss'],
})
export class ButtonHelpComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  async openModal(){
    let modal = await this.modalCtrl.create({
      component:HelpModalPage,
      componentProps:{mensagem:"Amigo estou aqui"}
      

    })

    return await modal.present()
  }  
}
