import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicLogicService {
  valueInput: any = ''
  constructor() { }
  // 
  checkExpression(valueInput, expression) {
    if (valueInput === null || valueInput === "" || valueInput === undefined) {
      return false
    }
    let result
    this.valueInput = valueInput

    result = this.getResultExpression(expression)
    return result
  }


  getResultExpression(expression) {
    let result

    switch (expression[1]) {
      case '=':

        result = this.isEqual(this.valueInput, expression[2])

        break;

      case 'contains':
        if (this.valueInput) {
          result = this.contains(this.valueInput, expression[2])
        }

        break;
      case 'notcontains':
        if (this.valueInput) {
          result = this.notContains(this.valueInput, expression[2])
        }
        break;
      case '<>':
        result = this.isNotEqual(this.valueInput, expression[2])

        break;

      case '>':
        result = this.isMoreThan(this.valueInput, expression[2])

        break;

      case '<':
        result = this.isLessThan(this.valueInput, expression[2])

        break;

      case '<=':
        result = this.isLessOrEqual(this.valueInput, expression[2])

        break;

      case '>=':
        result = this.isMoreOrEqual(this.valueInput, expression[2])

        break;
      case 'empty':
        result = this.isEmpty(this.valueInput, expression[2])

        break;
      case 'notempty':
        result = this.isNotEmpty(this.valueInput, expression[2])

        break;

      default:
        break;
    }
    return result
  }

  isEqual(a, b) {
    let result = false
    if (a == b || String(a) == b) {
      result = true
    }
    return result
  }
  isNotEqual(a, b) {
    let result = false
    if (a != b || String(a) != b) {
      result = true
    }
    return result
  }
  contains(a, b) {

    return a.includes(b);
  }
  notContains(a, b) {
    return !a.includes(b);
  }

  isEmpty(a, b) {
    let result = false
    if (!a) {
      result = true
    }
    return result
  }
  isNotEmpty(a, b) {
    
    let result = false
    if (a) {
      result = true
    }
    return result
  }
  isMoreThan(a, b) {
    let result = false
    if (a > b) {
      result = true
    }
    return result
  }
  isLessThan(a, b) {
    let result = false
    if (a < b) {
      result = true
    }
    return result
  }
  isMoreOrEqual(a, b) {
    let result = false
    if (a >= b) {
      result = true
    }
    return result
  }
  isLessOrEqual(a, b) {
    let result = false
    if (a <= b) {
      result = true
    }
    return result
  }
}
