import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {
  contact:any
  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.contact = JSON.parse(localStorage.getItem('theme')).contact
   }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
