import { AbstractControl, ValidatorFn } from "@angular/forms";

export class CustomValidators {
    static regex(params: RegExp | any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: string } | null => {
            if (control.value != undefined && eval(params).test(control.value)) {
                return { regexError: 'Campo inválido' };
            }
            return null;
        }
    }

}