import { ValidationService } from './validation';
import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class TabsService implements IService {

  constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

  getTitle = (): string => 'Tabs';

  getAllThemes = (): Array<any> => {
    return [
      { 'url': 'tab/0/tab/tab1', 'title': 'Navigation - Bottom Text', 'theme': 'layout1' },
      { 'url': 'tab/1/tab/tab4', 'title': 'Navigation - Bottom Icons', 'theme': 'layout2' },
      { 'url': 'tab/2/tab/tab7', 'title': 'Navigation - Top Text', 'theme': 'layout3' },
      { 'url': 'tab/3/tab/tab10', 'title': 'Navigation - Top Icons', 'theme': 'layout4' }
    ];
  }

  getDataForTheme = (menuItem: any): any => {
    return this[
      'getDataFor' +
      menuItem.charAt(0).toUpperCase() +
      menuItem.slice(1)
    ]();
  }

  //* Data Set for page 1
  getDataForTab1 = (): any => {
    return {
      'slider': [
          {
            'avatar': 'assets/imgs/avatar/0.jpg',
            'title': 'Tiffany Pearson',
            'subtitle': 'Product Designer',
            'btnFollowing': 'Follow',
            'message': 'Message',
          },
          {
            'avatar': 'assets/imgs/avatar/1.jpg',
            'title': 'Tiffany Pearson',
            'subtitle': 'Product Designer',
            'btnFollowing': 'Follow',
            'message': 'Message',
          },
          {
            'avatar': 'assets/imgs/avatar/2.jpg',
            'title': 'Tiffany Pearson',
            'subtitle': 'Product Designer',
            'btnFollowing': 'Follow',
            'message': 'Message',
          }
      ],
      'items': [
          {
              'id': 1,
              'image': 'assets/imgs/background/big-6.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'

          },
          {
              'id': 2,
              'image': 'assets/imgs/background/big-5.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 3,
              'image': 'assets/imgs/background/big-4.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 4,
              'image': 'assets/imgs/background/big-3.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 5,
              'image': 'assets/imgs/background/big-2.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 6,
              'image': 'assets/imgs/background/big-1.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          }
      ]
    };
  }

  //* Data Set for page 2
  getDataForTab2 = (): any => {
    return {
        'items': [
            {
                'id': 1,
                'image': 'assets/imgs/background/1.jpg',
                'title': 'Nikon sdasd',
                'numberProducts': '123 Products'
            },
            {
                'id': 2,
                'image': 'assets/imgs/background/2.jpg',
                'title': 'Macbook Air',
                'numberProducts': '123 Products'
            },
            {
                'id': 3,
                'image': 'assets/imgs/background/3.jpg',
                'title': 'Macbook Air',
                'numberProducts': '123 Products'
            },
            {
                'id': 4,
                'image': 'assets/imgs/background/4.jpg',
                'title': 'Nikon DF Retro',
                'numberProducts': '123 Products'
            },
            {
                'id': 5,
                'image': 'assets/imgs/background/6.jpg',
                'title': 'Nikon DF Retro',
                'numberProducts': '123 Products'
            },
            {
                'id': 6,
                'image': 'assets/imgs/background/7.jpg',
                'title': 'Nikon DF Retro',
                'numberProducts': '123 Products'
            }
        ]
    };
  }

  //* Data Set for page 3
  getDataForTab3 = (): any => {
    return {
      'items': [
          {
              'id': 1,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'

          },
          {
              'id': 2,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 3,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 4,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 5,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 6,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 7,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 8,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          }
      ]
    };
  }

  //* Data Set for page 4
  getDataForTab4 = (): any => {
    return {
      'items': [
          {
              'id': 1,
              'title': 'Molecule ionic New UI Kit',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/1.jpg',
              'avatar': 'assets/imgs/avatar/1.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          },
          {
              'id': 2,
              'title': 'What’s new in ionic New?',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/2.jpg',
              'avatar': 'assets/imgs/avatar/2.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          },
          {
              'id': 3,
              'title': 'What’s new in ionic New?',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/5.jpg',
              'avatar': 'assets/imgs/avatar/3.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          },
          {
              'id': 4,
              'title': 'What’s new in ionic New?',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/3.jpg',
              'avatar': 'assets/imgs/avatar/4.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          },
          {
              'id': 5,
              'title': 'What’s new in ionic New?',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/1.jpg',
              'avatar': 'assets/imgs/avatar/5.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          }
      ]
    };
  }

  //* Data Set for page 5
  getDataForTab5 = (): any => {
    return {
      'items': [
          {
              'id': 1,
              'image': 'assets/imgs/background/shop-7.jpg',
              'title': 'Nikon DF Retro',
              'price': 895.65,
              'count': 1
          },
          {
              'id': 2,
              'image': 'assets/imgs/background/shop-6.jpg',
              'title': 'Macbook Air',
              'price': 895.65,
              'count': 1
          },
          {
              'id': 3,
              'image': 'assets/imgs/background/shop-7.jpg',
              'title': 'Macbook Air',
              'price': 895.65,
              'count': 1
          },
          {
              'id': 4,
              'image': 'assets/imgs/background/shop-4.jpg',
              'title': 'Nikon DF Retro',
              'price': 895.65,
              'count': 1
          },
          {
              'id': 5,
              'image': 'assets/imgs/background/shop-5.jpg',
              'title': 'Nikon DF Retro',
              'price': 895.65,
              'count': 1
          },
          {
              'id': 6,
              'image': 'assets/imgs/background/shop-3.jpg',
              'title': 'Nikon DF Retro',
              'price': 895.65,
              'count': 1
          }
      ]
    };
  }

  //* Data Set for page 6
  getDataForTab6 = (): any => {
    return {
        'items': [
            {
                'id': 1,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/20.png',
                'title': 'Nikon Coolpix',
                'price': '$ 989.89',
                'btButton': 'Purchase'
            },
            {
                'id': 2,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/21.png',
                'title': 'Photo Camera',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            },
            {
                'id': 3,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/22.png',
                'title': 'Mavic Camera Drone',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            },
            {
                'id': 4,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/23.png',
                'title': 'Macbook Air',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            },
            {
                'id': 5,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/20.png',
                'title': 'Nikon Coolpix',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            },
            {
                'id': 6,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/21.png',
                'title': 'Photo Camera',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            }
        ]
    };
  }

  //* Data Set for page 7
  getDataForTab7 = (): any => {
    return {
      'slider': [
          {
            'avatar': 'assets/imgs/avatar/0.jpg',
            'title': 'Tiffany Pearson',
            'subtitle': 'Product Designer',
            'btnFollowing': 'Follow',
            'message': 'Message',
          },
          {
            'avatar': 'assets/imgs/avatar/1.jpg',
            'title': 'Tiffany Pearson',
            'subtitle': 'Product Designer',
            'btnFollowing': 'Follow',
            'message': 'Message',
          },
          {
            'avatar': 'assets/imgs/avatar/2.jpg',
            'title': 'Tiffany Pearson',
            'subtitle': 'Product Designer',
            'btnFollowing': 'Follow',
            'message': 'Message',
          }
      ],
      'items': [
          {
              'id': 1,
              'image': 'assets/imgs/background/big-6.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'

          },
          {
              'id': 2,
              'image': 'assets/imgs/background/big-5.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 3,
              'image': 'assets/imgs/background/big-4.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 4,
              'image': 'assets/imgs/background/big-3.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 5,
              'image': 'assets/imgs/background/big-2.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 6,
              'image': 'assets/imgs/background/big-1.jpg',
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'btReadMore': 'Read More',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          }
      ]
    };
  }

  //* Data Set for page 8
  getDataForTab8 = (): any => {
    return {
      'items': [
          {
              'id': 1,
              'image': 'assets/imgs/background/1.jpg',
              'title': 'Nikon sdasd',
              'numberProducts': '123 Products'
          },
          {
              'id': 2,
              'image': 'assets/imgs/background/2.jpg',
              'title': 'Macbook Air',
              'numberProducts': '123 Products'
          },
          {
              'id': 3,
              'image': 'assets/imgs/background/3.jpg',
              'title': 'Macbook Air',
              'numberProducts': '123 Products'
          },
          {
              'id': 4,
              'image': 'assets/imgs/background/4.jpg',
              'title': 'Nikon DF Retro',
              'numberProducts': '123 Products'
          },
          {
              'id': 5,
              'image': 'assets/imgs/background/6.jpg',
              'title': 'Nikon DF Retro',
              'numberProducts': '123 Products'
          },
          {
              'id': 6,
              'image': 'assets/imgs/background/7.jpg',
              'title': 'Nikon DF Retro',
              'numberProducts': '123 Products'
          }
      ]
    };
  }

  //* Data Set for page 9
  getDataForTab9 = (): any => {
    return {
      'items': [
          {
              'id': 1,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'

          },
          {
              'id': 2,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 3,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 4,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 5,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 6,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 7,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          },
          {
              'id': 8,
              'category': 'TECHNOLOGY',
              'subtitle': 'How might we help people become better-informed citizens?',
              'iconLike': 'heart',
              'numberLike': '56',
              'comments': 'chatbubble',
              'numberCommnet': '65'
          }
      ]
    };
  }

  //* Data Set for page 7
  getDataForTab10 = (): any => {
    return {
      'items': [
          {
              'id': 1,
              'title': 'Molecule ionic New UI Kit',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/1.jpg',
              'avatar': 'assets/imgs/avatar/1.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          },
          {
              'id': 2,
              'title': 'What’s new in ionic New?',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/2.jpg',
              'avatar': 'assets/imgs/avatar/2.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          },
          {
              'id': 3,
              'title': 'What’s new in ionic New?',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/5.jpg',
              'avatar': 'assets/imgs/avatar/3.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          },
          {
              'id': 4,
              'title': 'What’s new in ionic New?',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/3.jpg',
              'avatar': 'assets/imgs/avatar/4.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          },
          {
              'id': 5,
              'title': 'What’s new in ionic New?',
              'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
              'image': 'assets/imgs/background/1.jpg',
              'avatar': 'assets/imgs/avatar/5.jpg',
              'avatarTitle': 'Grace Palmer',
              'avatarSubTitle': '29 Articles',
              'btnFollowing': 'Following'
          }
      ]
    };
  }

  //* Data Set for page 8
  getDataForTab11 = (): any => {
    return {
      'items': [
        {
            'id': 1,
            'image': 'assets/imgs/background/shop-7.jpg',
            'title': 'Nikon DF Retro',
            'price': 895.65,
            'count': 1
        },
        {
            'id': 2,
            'image': 'assets/imgs/background/shop-6.jpg',
            'title': 'Macbook Air',
            'price': 895.65,
            'count': 1
        },
        {
            'id': 3,
            'image': 'assets/imgs/background/shop-7.jpg',
            'title': 'Macbook Air',
            'price': 895.65,
            'count': 1
        },
        {
            'id': 4,
            'image': 'assets/imgs/background/shop-4.jpg',
            'title': 'Nikon DF Retro',
            'price': 895.65,
            'count': 1
        },
        {
            'id': 5,
            'image': 'assets/imgs/background/shop-5.jpg',
            'title': 'Nikon DF Retro',
            'price': 895.65,
            'count': 1
        },
        {
            'id': 6,
            'image': 'assets/imgs/background/shop-3.jpg',
            'title': 'Nikon DF Retro',
            'price': 895.65,
            'count': 1
        }
      ]
    };
  }

  //* Data Set for page 9
  getDataForTab12 = (): any => {
    return {
        'items': [
            {
                'id': 1,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/20.png',
                'title': 'Nikon Coolpix',
                'price': '$ 989.89',
                'btButton': 'Purchase'
            },
            {
                'id': 2,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/21.png',
                'title': 'Photo Camera',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            },
            {
                'id': 3,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/22.png',
                'title': 'Mavic Camera Drone',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            },
            {
                'id': 4,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/23.png',
                'title': 'Macbook Air',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            },
            {
                'id': 5,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/20.png',
                'title': 'Nikon Coolpix',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            },
            {
                'id': 6,
                'iconLike': 'heart',
                'image': 'assets/imgs/background/21.png',
                'title': 'Photo Camera',
                'price': '$ 895.65',
                'btButton': 'Purchase'
            }
        ]
    };
  }

  load(item: string): Observable<any> {
    this.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('tab/' + item)
          .valueChanges()
          .subscribe(snapshot => {
            this.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            this.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        this.loadingService.hide();
        observer.next(this.getDataForTheme(item));
        observer.complete();
      });
    }
  }
}
