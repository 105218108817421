import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ViewDetailsComponent } from './pages/view-details/view-details.component';
import { IntroEasyinspectComponent } from './pages/intro-easyinspect/intro-easyinspect.component';
import { NewUserComponent } from './pages/new-user/new-user.component';
import { VistoryCompleteSuccessful } from './pages/vistory-complete-successfull/vistory-complete-successfull.component';
import { VistoryHasProblem } from './pages/vistory-has-problem/vistory-has-problem.component';
import { VistoryNotFound } from './pages/vistory-not-found/vistory-not-found.component';
import { VideoSignature } from './pages/video-signature/video-signature.page';
import { RedirectGuard } from './core/guards/redirect.guard';
import { VistoryNotEnabledComponent } from './pages/vistory-not-enabled/vistory-not-enabled.component';
import { VistoryExpiratedComponent } from './pages/vistory-expirated/vistory-expirated.component';
import { InvalidLinkComponent } from './pages/invalid-link/invalid-link.component';
import { DesktopGuard } from './core/guards/desktop.guard';
import { NotDeviceComponent } from './pages/not-device/not-device.component';
import { TestComponent } from './pages/test/test.component';

const routes: Routes = [
  { path: ':uniqueId', redirectTo: ':uniqueId/inspection/0', pathMatch: 'full' },
  {
    path: ':uniqueId/inspection/:type',
    loadChildren: () => import('./pages/item-details-splash-screen/item-details-splash-screen.module').then(m => m.ItemDetailsSplashScreenPageModule)
  },
  {
    path: ':uniqueId/intro-easyispect', canActivate: [DesktopGuard, RedirectGuard],
    component: IntroEasyinspectComponent
  },
  {
    path: ':uniqueId/new-user', canActivate: [DesktopGuard, RedirectGuard],
    component: NewUserComponent,
  },
  {
    path: ':uniqueId/dashboard', canActivate: [DesktopGuard, RedirectGuard],
    component: DashboardComponent
  },
  {

    path: ':uniqueId/vistory-not-found', 
    component: VistoryNotFound
  },
  {
    path: ':uniqueId/vistory-has-problem',
    component: VistoryHasProblem
  },
  {
    path: ':uniqueId/vistory-complete-successfull',
    component: VistoryCompleteSuccessful
  },
  {
    path: ':uniqueId/vistory-not-enabled',
    component: VistoryNotEnabledComponent
  },
  {
    path: ':uniqueId/invalid-link',
    component: InvalidLinkComponent
  },
  {
    path: ':uniqueId/vistory-expirated',
    component: VistoryExpiratedComponent
  },
  {
    path: ':uniqueId/view-details/:id', canActivate: [DesktopGuard, RedirectGuard],
    component: ViewDetailsComponent
  },
  {
    path: ':uniqueId/video-signature', canActivate: [DesktopGuard, RedirectGuard],
    component: VideoSignature
  },
  {
    path: ':uniqueId/register-equipment', canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/register-equipment/register-equipment.module').then(m => m.RegisterEquipmentPageModule)
  },
  {
    path: ':uniqueId/quiz', canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/quiz/quiz.module').then(m => m.QuizModule)
  },
  {
    path: ':uniqueId/welcome', canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: ':uniqueId/initial',  canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/initial/initial.module').then(m => m.InitialPageModule)
  },
  {
    path: ':uniqueId/pending', canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/pending/pending.module').then(m => m.PendingModule)
  },
  {
    path: ':uniqueId/not-device',
    component: NotDeviceComponent
  },
  {
    path: ':uniqueId/choose-method',
    loadChildren: () => import('./pages/choose-method/choose-method.module').then(m => m.ChooseMethodPageModule)
  },
  {
    path: ':uniqueId/help-modal',
    loadChildren: () => import('./shared/components/help-modal/help-modal.module').then(m => m.HelpModalPageModule)
  },
  {
    path: ':uniqueId/digital-signature',
    loadChildren: () => import('./pages/digital-signature/digital-signature.module').then(m => m.DigitalSignaturePageModule)
  },
  { path: ':uniqueId/test', component: TestComponent },

  
  { path: ':uniqueId/:anotherPath', redirectTo: ':uniqueId/:anotherPath/inspection/0', pathMatch: 'full' },
  {
    path: ':uniqueId/:anotherPath/inspection/:type',
    loadChildren: () => import('./pages/item-details-splash-screen/item-details-splash-screen.module').then(m => m.ItemDetailsSplashScreenPageModule)
  },
  {
    path: ':uniqueId/:anotherPath/intro-easyispect', canActivate: [DesktopGuard, RedirectGuard],
    component: IntroEasyinspectComponent
  },
  {
    path: ':uniqueId/:anotherPath/new-user', canActivate: [DesktopGuard, RedirectGuard],
    component: NewUserComponent,
  },
  {
    path: ':uniqueId/:anotherPath/dashboard', canActivate: [DesktopGuard, RedirectGuard],
    component: DashboardComponent
  },
  {

    path: ':uniqueId/:anotherPath/vistory-not-found', 
    component: VistoryNotFound
  },
  {
    path: ':uniqueId/:anotherPath/vistory-has-problem',
    component: VistoryHasProblem
  },
  {
    path: ':uniqueId/:anotherPath/vistory-complete-successfull',
    component: VistoryCompleteSuccessful
  },
  {
    path: ':uniqueId/:anotherPath/vistory-not-enabled',
    component: VistoryNotEnabledComponent
  },
  {
    path: ':uniqueId/:anotherPath/invalid-link',
    component: InvalidLinkComponent
  },
  {
    path: ':uniqueId/:anotherPath/vistory-expirated',
    component: VistoryExpiratedComponent
  },
  {
    path: ':uniqueId/:anotherPath/view-details/:id', canActivate: [DesktopGuard, RedirectGuard],
    component: ViewDetailsComponent
  },
  {
    path: ':uniqueId/:anotherPath/video-signature', canActivate: [DesktopGuard, RedirectGuard],
    component: VideoSignature
  },
  {
    path: ':uniqueId/:anotherPath/register-equipment', canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/register-equipment/register-equipment.module').then(m => m.RegisterEquipmentPageModule)
  },
  {
    path: ':uniqueId/:anotherPath/quiz', canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/quiz/quiz.module').then(m => m.QuizModule)
  },
  {
    path: ':uniqueId/:anotherPath/welcome', canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: ':uniqueId/:anotherPath/initial',  canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/initial/initial.module').then(m => m.InitialPageModule)
  },
  {
    path: ':uniqueId/:anotherPath/pending', canActivate: [DesktopGuard, RedirectGuard],
    loadChildren: () => import('./pages/pending/pending.module').then(m => m.PendingModule)
  },
  {
    path: ':uniqueId/:anotherPath/not-device',
    component: NotDeviceComponent
  },
  {
    path: ':uniqueId/:anotherPath/choose-method',
    loadChildren: () => import('./pages/choose-method/choose-method.module').then(m => m.ChooseMethodPageModule)
  },
  {
    path: ':uniqueId/:anotherPath/help-modal',
    loadChildren: () => import('./shared/components/help-modal/help-modal.module').then(m => m.HelpModalPageModule)
  },
  {
    path: ':uniqueId/:anotherPath/digital-signature',
    loadChildren: () => import('./pages/digital-signature/digital-signature.module').then(m => m.DigitalSignaturePageModule)
  },
  { path: ':uniqueId/:anotherPath/test', component: TestComponent },
  { path: '**', component: VistoryNotFound }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
