import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CoreService } from 'src/app/shared/services/core.service';

@Injectable({
  providedIn: 'root'
})
export class DesktopGuard implements CanActivate {

  constructor(private platform: Platform, private coreService: CoreService) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const deviceArr = this.platform.platforms();
    
    if (deviceArr.includes('desktop') && route.url[1].path != 'NotDeviceComponent') {
      this.coreService.navCtrl.navigateForward(`${route.params.uniqueId}/vistory-expirated`);
      return false
    }
    return true;
  }

}
