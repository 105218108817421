import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import moment from 'moment';
import { CoreService } from 'src/app/shared/services/core.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {

  constructor(private storage: AngularFireStorage, private coreService: CoreService) { }

  uploadFile(basePath: string, file: any): { ref: AngularFireStorageReference, task: AngularFireUploadTask } {
    const { latitude, longitude } = this.coreService.position.value;
    const filePath = `${basePath}/${file.name}`;
    const storageRef = this.storage.ref(filePath);
    const metadata = {
      customMetadata: {
        latitude,
        longitude,
        createDate: moment().format("DD/MM/YYYY HH:mm:ss")
      }
    };
    const uploadTask = this.storage.upload(filePath, file.archive, metadata);
    return { ref: storageRef, task: uploadTask };
  }

  deleteFileFromStorage(basePath: string, name: string) {
    const storageRef = this.storage.ref(basePath);
    return storageRef.child(name).delete();
  }
}
