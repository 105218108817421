import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private modalService: NgbModal) { }

  closeModal(modal: NgbActiveModal, result?: any): void {
    modal.close(result);
  }

  openModal(content: any, options?: NgbModalOptions): NgbModalRef {
    return this.modalService.open(content, options);
  }

  private confirmResult: BehaviorSubject<any> = new BehaviorSubject(false)

  showConfirmDialog(
    messageTitle: string,
    messageContent: string,
    disableButton?: boolean,
    type: string = null,
    cancelButtonText?: string,
    submitButtonText?: string,
    contentRef?: any
  ) {
    const modalRef: NgbModalRef = this.modalService.open(ConfirmDialogComponent, { size: 'md', centered: true });

    modalRef.componentInstance.messageTitle = messageTitle;
    modalRef.componentInstance.messageContent = messageContent;
    modalRef.componentInstance.contentRef = modalRef;

    modalRef.componentInstance.disableButton = disableButton ? disableButton : false;
    cancelButtonText ? modalRef.componentInstance.cancelButtonText = cancelButtonText : cancelButtonText;
    submitButtonText ? modalRef.componentInstance.submitButtonText = submitButtonText : submitButtonText;
    type ? modalRef.componentInstance.type = type : type;

    return this.confirmResult;

  }

  setConfirm(value: boolean) {
    this.confirmResult.next(value)
  }
}
