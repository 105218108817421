import { AfterViewInit, Component, ViewChild, OnInit,ViewEncapsulation  } from '@angular/core';
import { FormGroup,FormArray,FormControl,FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-paneldynamic',
  templateUrl: './paneldynamic.component.html',
  styleUrls: ['./paneldynamic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PanelDynamicComponent implements OnInit, AfterViewInit {
  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;
  field: any;
  group: FormGroup;
  inspectionData: any = {};
  values: any = [];
  forms:any = new FormArray([]);
  formsElements:any = [];
  initialized:boolean = false;
  constructor(private fb: FormBuilder) { 

  }  
  ngAfterViewInit(): void {
  //  if(!this.initialized){
  //    let value = this.group.get(this.field.name).value || [];
  //    if(value && value.length > 0)
  //     this.loadData(value);
  //  }
  }

  ngOnInit() {
    this.forms.valueChanges.subscribe((value)=>{
      this.group.controls[this.field.name].setValue(value);
    });

    this.group.get(this.field.name).valueChanges.subscribe((value)=>{
      if(!this.initialized)
        this.loadData(value);
    })
  }

  convertObjectToFormGroup(json){
    if(!json)
      return json;
    Object.keys(json).forEach(function (key) {
      if(Array.isArray(json[key])){
        console.log(json, json[key], '4')
        // json[key] = json[key]
        if(json[key].length > 0 && Array.isArray(json[key][0])) {
          json[key] = json[key];
          return;
        }
        if(json[key].length > 0 && json[key][0]?.format) {
          console.log(json, '1')
          let arr: any[] = []
          arr.push(json[key])
          console.log(arr, 'arr')
          json[key] = arr;
        } else {
          console.log(json, '3')
          json[key] = this.fb.array(json[key]);
        }

        //RECURSIVA PARA ARRAY DENTRO DE OBJETOS.
        //COMENTEI PORQUE NÃO TESTEI MTO BEM
        /**let newArray = this.fb.array([]);
        json[key].forEach(function (item) {
          let newObj = this.convertObjectToFormGroup(json[key]);
          newArray.push(this.fb.group(newObj));
        }.bind(this));
        json[key] = newArray;**/
      }
      else if(typeof json[key] == 'object'){
        json[key] = this.convertObjectToFormGroup(json[key]);
      }
    }.bind(this));

    return json;
  }

  loadData(value){
    this.initialized=true;
    console.log(value);
    // for (const item of value) {
    //   let newElement = this.convertObjectToFormGroup(item);
    //   console.log(newElement,'newElement')
    //   var group = this.fb.group(item);
    //   this.formsElements.push(value)
    //   this.forms.push(group);
    // }

    (value || []).forEach(element => {
      var newElement = this.convertObjectToFormGroup(element);
      var group = this.fb.group(newElement);

      let templateElements = JSON.parse(JSON.stringify(this.field.templateElements || []));
      if((this.field.reviewConfiguration || []).length > 0){
        let nextIndex = (this.formsElements || []).length;
        let possibleReviewConfiguration = (this.field.reviewConfiguration || []).filter((config)=>config.index == nextIndex);
        if((possibleReviewConfiguration || []).length > 0){
          templateElements.forEach(element => {
            let reviewConfigFounded = possibleReviewConfiguration.filter((config)=>config.propertyName == element.name);
            if((reviewConfigFounded || []).length > 0)
            {
              element.needReview = true;
              element.reviewDescription = reviewConfigFounded[0].reviewDescription
            }
          });
        }
      }/****/
      this.formsElements.push(templateElements);
      this.forms.push(group);
    });
  }

  addNewForm(){
      this.initialized=true;
      let nextIndex = (this.forms.controls || []).length;
      let newGroup = {};
      newGroup[nextIndex] = null;
      let templateElements = JSON.parse(JSON.stringify(this.field.templateElements || []));
      this.formsElements.push(templateElements);
      this.forms.push(this.fb.group({}));
  }

  removeForm(index){
    this.initialized=true;
    this.forms.removeAt(index);
  }
}
