import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorageReference } from '@angular/fire/storage';
import { FormGroup } from '@angular/forms';
import { Capacitor, Plugins } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import * as WebVPPlugin from 'capacitor-video-player'
import { distinctUntilChanged, take } from 'rxjs/operators';
import { FirebaseStorageService } from 'src/app/core/services/firebase-storage.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { VideoService } from 'src/app/services/video.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

const { CapacitorVideoPlayer } = Plugins;

@Component({
  selector: 'app-record-modal',
  templateUrl: './record-modal.component.html',
  styleUrls: ['./record-modal.component.scss'],
})
export class RecordModalComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  field: FileInput;
  group: FormGroup;
  mediaRecorder: MediaRecorder;
  videoPlayer: any;
  isRecording = false;
  videos = [];
  videoIsEmpty = true;
  chunks = []
  videoPlayed: boolean = false;
  private resultSubscribe: any;
  @ViewChild('video') captureElement: ElementRef<HTMLVideoElement>;
  @ViewChild('recorderVideo') modalRecord: ElementRef<any>;
  video = document.querySelector('video');
  secondsToStart:any = 3;
  waitStartingVideo:any = false;
  loading:any = false;
  constructor(
    private videoService: VideoService,
    private coreService: CoreService,
    private firebaseStorageService: FirebaseStorageService,
    private utilsService: UtilsService,
    private loaderService: LoaderService,
    private modalController: ModalController,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  async ngAfterViewInit() {
    this.delete();
    this.videos = await this.videoService.loadVideos();
    this.videoIsEmpty = this.videos.length == 0 ? true : false
    if (Capacitor.isNative) {
      this.videoPlayer = CapacitorVideoPlayer;
    } else {
      this.videoPlayer = WebVPPlugin.CapacitorVideoPlayer
    }
    this.video = document.querySelector('video')
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {

  }

  public boundedControlTimeToStartRecordVideo = this.controlTimeToStartRecordVideo.bind(this);
  async controlTimeToStartRecordVideo(){
    if(this.secondsToStart == 0)
    {
      this.waitStartingVideo = false;
      return;
    } 

    this.secondsToStart = this.secondsToStart -1;
    setTimeout(this.boundedControlTimeToStartRecordVideo,1000);
  }
  async startRecordVideo(){

    await this.recordVideo();
    this.waitStartingVideo = true;
    this.secondsToStart=3;
    setTimeout(this.boundedControlTimeToStartRecordVideo,1000);
  }

  async recordVideo() {
    this.chunks = [];
    let stream;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment'
        },
        audio: true
      })
    } catch (ex) {
    }

    if (stream != undefined) {
      this.captureElement.nativeElement.srcObject = stream;
      this.isRecording = true;

      const options = { mimeType: 'video/ogg' }
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.start();

      let self = this;
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          self.chunks.push(event.data)
        }
      }

      this.mediaRecorder.addEventListener('stop', async (event) => {
        const videoBuffer = new Blob(self.chunks, { type: 'video/mp4' });

        await self.videoService.storeVideo(videoBuffer);

        self.videos = self.videoService.videos;
        self.videoIsEmpty = false;
      })

    } else {
      this.coreService.videoComponent.next(false)
      const result$ = this.utilsService.showConfirmDialog(`Oops...`, 'Precisamos de acesso a câmera e microfone!', false, 'danger', '', 'Ok')
      

      this.unsubscribeResult();

      this.resultSubscribe = result$.asObservable().pipe(distinctUntilChanged())
        .subscribe(success => {
          this.utilsService.setConfirm(false);
        })
    }
  }

  delete() {
    this.videoIsEmpty = true;
    this.videos = [];
    this.videoService.deleteVideo();
  }

  cancel() {
    this.videoIsEmpty = true;
    this.videos = [];
    this.videoService.deleteVideo();
  }

  stopRecord() {
    this.mediaRecorder.stop();
    this.captureElement.nativeElement.srcObject = null;
    this.captureElement.nativeElement.src = null;
    this.isRecording = false;
    this.mediaRecorder = null;
  }

  async play(video) {
    const base64data = await this.videoService.getVideoUrl(video);
    const full = document.getElementById('fullscreen');
    full.hidden = false;
    await this.videoPlayer.initPlayer({
      mode: 'fullscreen',
      url: base64data,
      playerId: 'fullscreen',
      componentTag: 'app-record-modal'
    });
    this.videoPlayed = true;
  }

  async upload() {
    
    this.loading=true;
    try{
      const video = await this.videoService.getVideoUrl(this.videos[0]);
      const uuid = this.coreService.generateUUID();
      const base64Response = await fetch(video);
      const blob = await base64Response.blob();
      const payload = {
        name: uuid,
        archive: blob
      }
      await this.uploadToStorage(payload, base64Response, video, uuid)
      
      this.loading=false;
    }catch(ex){
      
      this.loading=false;
    }
  }

  private async uploadToStorage(payload: any, base64: Response, file: any, uuid: string) {
    let { task, ref } = this.firebaseStorageService.uploadFile(this.coreService.inspectionUniqueId.value, payload)
    if ((await task).state == 'success') {
      this.getDownloadUrl(ref, file, base64, uuid);
      this.delete();
    }
  }

  private getDownloadUrl(ref: AngularFireStorageReference, file: any, base64: Response, uuid: string) {
    ref.getDownloadURL().pipe(take(1))
      .subscribe(downloadUrl => {
        this.videos.push({
          uuid,
          downloadUrl,
          format: this.getFormat(file),
          content: uuid,
          type: 'video_uploaded'
        })
          this.dismissModal(this.videos);        
      })
  }

  private getFormat(image: string) {
    return image.substring(image.indexOf('/') + 1).split(';')[0];
  }

  private unsubscribeResult() {
    if (this.resultSubscribe != undefined) { this.resultSubscribe.unsubscribe() }
  }

  dismissModal(videos?) {
    this.waitStartingVideo=false;
    this.loading=false;
    if ((videos || []).length > 0) {
      this.modalController.dismiss(videos);
    }
    this.modalController.dismiss();
    this.delete();
     
  }

}
