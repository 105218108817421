import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core'
import * as WebVPPlugin from 'capacitor-video-player'
import { CoreService } from 'src/app/shared/services/core.service';
import { FirebaseStorageService } from 'src/app/core/services/firebase-storage.service';
import { AngularFireStorageReference } from '@angular/fire/storage/ref';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { LoaderService } from 'src/app/core/services/loader.service';
import { FormGroup } from '@angular/forms';
import { VideoService } from 'src/app/services/video.service';
import { take } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { RecordModalComponent } from './record-modal/record-modal.component';
import { PreviewComponent } from '../../preview/preview.component';
import { Subscription } from 'rxjs';

const { CapacitorVideoPlayer } = Plugins;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, AfterViewInit, AfterViewChecked {
  camPermission:any;
  videoComponent:any;
  field: FileInput;
  group: FormGroup;
  videos = [];
  initialized:any = false;
  modalRecord: HTMLIonModalElement = null
  private resultSubscribe: Subscription;

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private loaderService: LoaderService,
    private coreService: CoreService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private firebaseStorageService: FirebaseStorageService,
  ) { }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.initialized=true;
  }

  ngOnInit() {
    this.coreService.videoComponent.subscribe((value)=>{
      this.videoComponent = value
    })
    this.group.get(this.field.name).valueChanges.subscribe(res => {
      this.videos = res
    })
  }

  async openModal() {
    this.modalRecord = await this.modalController.create({
      component: RecordModalComponent,
      componentProps: {
        'field': this.field,
        'group': this.group,
      }
    });
    this.getVideoFromModal();
    return await this.modalRecord.present();
  }

  getVideoFromModal() {
    this.modalRecord.onDidDismiss().then(data => {
      if (data.data != undefined) {
        this.videos = data.data
      } else {
        this.videos = [];
      }
      this.setValue();
    })
  }

  setValue() {
    this.loaderService.isLoading.next(false);
    this.group.get(this.field.name).setValue(this.videos)
  }

  async presentModal(uuid: string, format: string, downloadUrl) {
    const name = `${uuid.slice(0, 7)}.${format}`;
    const modal = await this.modalController.create({
      component: PreviewComponent,
      componentProps: {
        'title': name,
        'downloadUrl': downloadUrl,
        'isVideo': true
      }
    });
    return await modal.present();
  }

  removePicture(index: number, uuid: string) {
    const result$ = this.utilsService.showConfirmDialog(`Remover vídeo`, 'Deseja continuar?', false, 'danger', 'NÃO', 'REMOVER');

    this.resultSubscribe = result$.asObservable().pipe(
      take(2),
      distinctUntilChanged()
      ).subscribe(success => {
        this.loaderService.isLoading.next(true);
        if (success) {
            this.firebaseStorageService.deleteFileFromStorage(this.coreService.inspectionUniqueId.value, uuid)
            this.utilsService.setConfirm(false)
            this.videos.splice(index, 1);
            this.setValue();
            this.coreService.eventSubmit.emit(this.videos)
        } else {
          this.loaderService.isLoading.next(false)  
        }
    })
  }

}
