import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IGetResumeProcessBySurveyDispatch } from '../models/gclaims.model';


const URL = `${environment.glcaimsAPI}/api/Survey`
const httpOptions = {
  headers: new HttpHeaders(
      {
          'Content-Type': 'application/json; charset=utf-8',
      })
};

const urls = {
  '85dc4250c618ddbdcb4f185ad61cb541': 'https://gclaimsequity-api-hml.wizsolucoes.com.br/api/Survey/GetResumeProcessBySurveyDispatch',
  'f7582350b471661c3d8dcdce488bc507': 'https://gclaims-equity.wizsolucoes.com.br/v2/api/Survey/GetResumeProcessBySurveyDispatch'
}

@Injectable({
  providedIn: 'root'
})
export class GclaimsService {

  constructor(private http: HttpClient) { }

  getInspection(uniqueId: string, anotherPath?: any): Observable<IGetResumeProcessBySurveyDispatch> {
    if(!anotherPath || anotherPath == 'undefined') {
      return this.http.post<IGetResumeProcessBySurveyDispatch>(`${URL}/GetResumeProcessBySurveyDispatch`, JSON.stringify(uniqueId), httpOptions);
    }
    return this.http.post<IGetResumeProcessBySurveyDispatch>(`${urls[anotherPath]}`, JSON.stringify(uniqueId), httpOptions);
  }

  getRequestDocuments(uniqueId: string): Observable<any> {
    return this.http.post<any>(`${URL}/GetSurveyPendingRequestDocuments`, JSON.stringify(uniqueId), httpOptions);
  }
}
