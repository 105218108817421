import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';
import { DataResult } from 'src/app/core/models/gclaims.model';
import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-intro-easyinspect',
  templateUrl: './intro-easyinspect.component.html',
  styleUrls: ['./intro-easyinspect.component.scss'],
})
export class IntroEasyinspectComponent implements OnInit {
  
  
  inspectionData: DataResult;
  user: any;
  optionsSlide:any = {allowTouchMove: true}
  @ViewChild('introSlider')  slides: IonSlides;
  
  
  
  constructor(
    private navCrtl: NavController,
    private coreService: CoreService
    ) { }
    
  ngOnInit() {
    this.inspectionData = this.coreService.getCurrentInspectionData;
    this.user = this.coreService.user.value
    if(this.user.name == null ||  this.user.name == ''){
      console.log('AttendedBy', this.inspectionData?.AttendedBy)
      this.user.name = this.inspectionData?.AttendedBy ?? this.inspectionData.InsuredName;
    }
    if (navigator.getUserMedia) {
      navigator.getUserMedia({ audio: true, video: {facingMode: 'user' }},
      function(success) {
        localStorage.setItem('camPermission','true')
        this.coreService.videoComponent.next(true)
        
      },
      function(err) {
        localStorage.setItem('camPermission','false')
        this.coreService.videoComponent.next(false)
         }
      );
   } else {
   }
   
  }

  nextSlide() {
    this.slides.slideNext();
  }
  prevSlide() {
    this.slides.slidePrev();
  }
  letsGo() {
    this.coreService.navigate('initial');
  }


}
