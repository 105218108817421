import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';

@Injectable({ providedIn: 'root' })
export class HomeService {

    constructor(public af: AngularFireDatabase) { }

    // Set data for - HOME PAGE
    getData = () => {
        return {
            'toolbarTitle': 'Molecule - Ionic Starter Template',
            'title': 'SAVE HOURS',
            'subtitle': 'OF DEVELOPING',
            'subtitle2': 'and make apps fast as light!',
            'link': 'https://devspush.com/documentation/12/',
            'description': 'For better understanding how our template works please read documentation.',
            'background': 'assets/imgs/logo/ionic4-logo.png',
            'skach': 'assets/imgs/background/icon-1.png',
            'logo': 'assets/imgs/logo/logo.png',
            'photoshop': 'assets/imgs/background/icon-2.png',
            'xd': 'assets/imgs/background/icon-3.png',
            'figma': 'assets/imgs/background/icon-4.png',
            'docs': 'assets/imgs/background/icon-5.png',
            'items': [
                {
                    'id': 1,
                    'title': 'Lauren Simpson',
                    'subtitle': 'Lorem ipsum dolor sit amet.',
                    'image': 'assets/imgs/avatar/0.jpg'
                }
            ]

            
        };
    }

    load(): Observable<any> {
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('home')
                    .valueChanges()
                    .subscribe(snapshot => {
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                observer.next(this.getData());
                observer.complete();
            });
        }
    }
}
