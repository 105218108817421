import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class MenuService implements IService {

  constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

  getId = (): string => 'menu';

  getTitle = (): string => 'UIAppTemplate';


  getDataForTheme = (menuItem: any) => {
    return {
      // 'imgLogo': 'assets/imgs/logo/login.png',
      'image': 'assets/imgs/logo/login.png',
      'title': 'Molecule UI Kit',
      'description': 'Multi-Purpose Starter Template for Ionic'
    };
  }

  //* Data Set for main menu
  getAllThemes = (): Array<any> => {
    return [
      {
        'url': 'items/listViews',
        'title': 'List Views',
        'theme': 'listViews',
        'icon': 'chevron-forward-circle',
        'listView': true,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/googleCards',
        'title': 'Cards',
        'theme': 'googleCards',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/parallax',
        'title': 'Parallax',
        'theme': 'parallax',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'settings',
        'title': 'RTL + Color Change',
        'theme': 'settings',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': true
      },
      {
        'url': 'items/login',
        'title': 'Login Pages',
        'theme': 'login',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/register',
        'title': 'Register Pages',
        'theme': 'register',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/forgetPassword',
        'title': 'Forgot Password Pages',
        'theme': 'forgetPassword',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/newPassword',
        'title': 'Old/New Password Pages',
        'theme': 'newPassword',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/imageGallery',
        'title': 'Image Gallery',
        'theme': 'imageGallery',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/splashScreens',
        'title': 'Splash Screen',
        'theme': 'splashScreens',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/checkBoxes',
        'title': 'Checkbox',
        'theme': 'checkBoxes',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/searchBars',
        'title': 'Search Bars',
        'theme': 'searchBars',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/textViews',
        'title': 'Typo + small components',
        'theme': 'textViews',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/wizard',
        'title': 'Wizard / Onboarding',
        'theme': 'wizard',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/tabs',
        'title': 'Tabs',
        'theme': 'tabs',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/timeline',
        'title': 'Timeline',
        'theme': 'timeline',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/radioButton',
        'title': 'Radio Button',
        'theme': 'radioButton',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/range',
        'title': 'Range',
        'theme': 'range',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/toggle',
        'title': 'Toggle',
        'theme': 'toggle',
        'icon': 'chevron-forward-circle',
        'listView': false,
        'component': '',
        'singlePage': false
      },
      {
        'url': 'items/select',
        'title': 'Select',
        'theme': 'select',
        'icon': 'chevron-forward-circle',
        'listView': true,
        'component': '',
        'singlePage': false
      }
    ];
  }

  getEventsForTheme = (menuItem: any): any => {
    return {};
  }

  prepareParams = (item: any) => {
    return {
      title: item.title,
      data: {},
      events: this.getEventsForTheme(item)
    };
  }

  load(item: any): Observable<any> {
    this.loadingService.show();
    if (AppSettings.IS_FIREBASE_ENABLED) {
      return new Observable(observer => {
        this.af
          .object('menu')
          .valueChanges()
          .subscribe(snapshot => {
            this.loadingService.hide();
            observer.next(snapshot);
            observer.complete();
          }, err => {
            this.loadingService.hide();
            observer.error([]);
            observer.complete();
          });
      });
    } else {
      return new Observable(observer => {
        this.loadingService.hide();
        observer.next(this.getDataForTheme(item));
        observer.complete();
      });
    }
  }
}
