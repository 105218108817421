export const propertiesEquipment = [{
    typeDevice: {
        label: 'Qual o tipo do aparelho?',
        value: '',
        disabled: false
    },
    ageDevice: {
        label: 'Qual a idade do aparelho?',
        value: '',
        disabled: false
    },
    reportDetailed: {
        label: 'Nos informe detalhadamente o que aconteceu',
        value: '',
        disabled: false
    },
    isCloseOfLocation: {
        label: 'Você está próximo do local onde ficava o aparelho?',
        value: '' ?? false,
        disabled: false
    },
    isRepared: {
        label: 'O aparelho está reparado?',
        value: '' ?? false,
        disabled: false
    },
    savedParts: {
        label: 'As peças foram guardadas e os salvados foram preservados?',
        value: '' ?? false,
        disabled: false
    },
    whatsPieceDamaged: {
        label: 'Qual é a peça danificada?',
        value: '',
        disabled: false
    },
    whoDiscarded: {
        label: 'Quem realizou o descarte?',
        value: '',
        disabled: false
    },
    whenDiscardedDay: {
        label: 'Quando foi feito o descarte?',
        value: '',
        disabled: false
    },
    whenDiscardedMonth: {
        label: '',
        value: '',
        disabled: true
    },
    whenDiscardedYear: {
        label: '',
        value: '',
        disabled: true
    },
    statusDevice: {
        label: 'O aparelho quando danificado estava com defeito ou não ligava?',
        value: '',
        disabled: false
    },
    otherEquipmentEnabled: {
        label: 'Havia outro equipamento danificado ligado ao aparelho em questão?',
        value: '' ?? false,
        disabled: false
    },
    otherEquipmentDamaged: {
        label: 'Havia outro equipamento danificado ligado na mesma tomada do aparelho em questão?',
        value: '' ?? false,
        disabled: false
    },
    defectWay: {
        label: 'O aparelho em questão já apresentou algum defeito ou foi reparado no passado?',
        value: '' ?? false,
        disabled: false
    },
    whatsDefect: {
        label: 'Qual defeito está ocorrendo com o aparelho?',
        value: '',
        disabled: false
    },
    defectTime: {
        label: 'O defeito ocorreu na hora ou momentos depois?',
        value: '' ?? false,
        disabled: false
    },
    defectFrequency: {
        label: 'O defeito ocorre ocasionalmente ou de forma definitiva?',
        value: '' ?? false,
        disabled: false
    },
    turnLigth: {
        label: 'O aparelho ainda acende alguma luz ou ainda tem algum tipo de função?',
        value: '' ?? false,
        disabled: false
    },
    carbonization: {
        label: 'Existe algum sinal de carbonização (princípio de incêndio)?',
        value: '' ?? false,
        disabled: false
    },
    pictureEnviroment: {
        label: 'Local do aparelho',
        value: '',
        disabled: false
    },
    pictureSavePart: {
        label: 'Foto do salvado',
        value: '',
        disabled: false
    },
    pictureDevice: {
        label: 'Foto do aparelho',
        value: '',
        disabled: false
    },
    pictureModelBrand: {
        label: 'Foto da marca do aparelho',
        value: '',
        disabled: false
    },
    pictureTurnLigth: {
        label: 'O aparelho ainda acende alguma luz ou ainda tem algum tipo de função?',
        value: '',
        disabled: false
    },
    pictureCarbonization: {
        label: 'Existe algum sinal de carbonização (princípio de incêndio)?',
        value: '',
        disabled: false
    }
}]