import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { ToastService } from './toast-service';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class WizardService implements IService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService, private toastCtrl: ToastService) { }

    getTitle = (): string => 'Wizard';

    getAllThemes = (): Array<any> => {
        return [
            { 'url': 'wizard/0', 'title': 'Walktrough-1', 'theme': 'layout1' },
            { 'url': 'wizard/1', 'title': 'eCommerce', 'theme': 'layout2' },
            { 'url': 'wizard/2', 'title': 'Profiles', 'theme': 'layout3' },
            { 'url': 'wizard/3', 'title': 'Social', 'theme': 'layout4' }
        ];
    }

    getDataForTheme = (menuItem: any): Array<any> => {
        return this[
            'getDataFor' +
            menuItem.theme.charAt(0).toUpperCase() +
            menuItem.theme.slice(1)
        ]();
    }

    //* Data Set for page 1
    getDataForLayout1 = (): any => {
        return {
            'toolbarTitle': 'Walktrough-1',
            'btnPrev': 'Previous',
            'btnNext': 'Next',
            'btnFinish': 'Finish',
            'items': [
                {
                    'image': 'assets/imgs/background/1.png',
                    'title': 'Molecule UI Kit',
                    'description': `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a aliquam`,
                    'button': 'Purchase Now'
                },
                {

                  'image': 'assets/imgs/background/2.png',
                  'title': 'Molecule UI Kit',
                  'description': `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a aliquam`,
                  'button': 'Purchase Now'
                },
                {
                  'image': 'assets/imgs/background/3.png',
                  'title': 'Molecule UI Kit',
                  'description': `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a aliquam`,
                  'button': 'Purchase Now'

                }
            ]
        };
    }

    //* Data Set for page 2
    getDataForLayout2 = (): any => {
        return {
            'toolbarTitle': 'eCommerce',
            'slider': [
                {
                  'iconLike': 'heart',
                  'image': 'assets/imgs/background/20.png',
                  'title': 'Nikon DF Retro',
                  'price': '$ 989.89',
                  'btButton': 'Purchase',
                  'isLike': false
                },
                {
                  'iconLike': 'heart',
                  'image': 'assets/imgs/background/21.png',
                  'title': 'Macbook Air',
                  'price': '$ 895.65',
                  'btButton': 'Purchase',
                  'isLike': false
                },
                {
                  'iconLike': 'heart',
                  'image': 'assets/imgs/background/23.png',
                  'title': 'Nikon DF Retro',
                  'price': '$ 895.65',
                  'btButton': 'Purchase',
                  'isLike': false
                },
                {
                  'iconLike': 'heart',
                  'image': 'assets/imgs/background/22.png',
                  'title': 'Nikon DF Retro',
                  'price': '$ 895.65',
                  'btButton': 'Purchase',
                  'isLike': false
                },
                {
                  'iconLike': 'heart',
                  'image': 'assets/imgs/background/21.png',
                  'title': 'Nikon DF Retro',
                  'price': '$ 895.65',
                  'btButton': 'Purchase',
                  'isLike': false
                },
            ],
            'items': [
                {
                    'id': 1,
                    'image': "assets/imgs/background/shop-2.jpg",
                    'name': "Cameras",
                    'number': '142 Products',
                },
                {
                    'id': 2,
                    'image': "assets/imgs/background/shop-1.jpg",
                    'name': "Cameras",
                    'number': '45 Products',
                },
                {
                    'id': 3,
                    'image': "assets/imgs/background/shop-3.jpg",
                    'name': "Cameras",
                    'number': '186 Products',
                },
                {
                    'id': 4,
                    'image': "assets/imgs/background/shop-4.jpg",
                    'name': "Cameras",
                    'number': '234 Products',
                },
                {
                    'id': 5,
                    'image': "assets/imgs/background/shop-5.jpg",
                    'name': "Cameras",
                    'number': '56 Products',
                },
                {
                    'id': 6,
                    'image': "assets/imgs/background/shop-6.jpg",
                    'name': "Cameras",
                    'number': '321 Products',
                },
                {
                    'id': 7,
                    'image': "assets/imgs/background/shop-7.jpg",
                    'name': "Cameras",
                    'number': '123 Products',
                },
                {
                    'id': 8,
                    'image': "assets/imgs/background/shop-1.jpg",
                    'name': "Cameras",
                    'number': '562 Products',
                },
                {
                    'id': 9,
                    'image': "assets/imgs/background/shop-1.jpg",
                    'name': "Cameras",
                    'number': '12 Products',
                },
                {
                    'id': 10,
                    'image': "assets/imgs/background/shop-1.jpg",
                    'name': "Cameras",
                    'number': '102 Products',
                }
            ]
        };
    }

    //* Data Set for page 3
    getDataForLayout3 = (): any => {
        return {
            'toolbarTitle': 'Profiles',
            'slider': [
                {
                  'avatar': 'assets/imgs/avatar/0.jpg',
                  'title': 'Tiffany Pearson',
                  'subtitle': 'Product Designer',
                  'btFollowing': 'Follow',
                  'message': 'Message',
                },
                {
                  'avatar': 'assets/imgs/avatar/1.jpg',
                  'title': 'Tiffany Pearson',
                  'subtitle': 'Product Designer',
                  'btFollowing': 'Follow',
                  'message': 'Message',
                },
                {
                  'avatar': 'assets/imgs/avatar/2.jpg',
                  'title': 'Tiffany Pearson',
                  'subtitle': 'Product Designer',
                  'btFollowing': 'Follow',
                  'message': 'Message',
                }
            ],
            'items': [
                {
                    'id': 1,
                    'category': 'ART & DESIGN',
                    'title': 'A New Design Model That Helps People Change Their Lives',
                    'image': 'assets/imgs/background/4.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countComment': 84,
                    'countLike': 67,
                    'isLike': false
                },
                {
                    'id': 2,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/1.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countComment': 84,
                    'countLike': 67,
                    'isLike': false
                },
                {
                    'id': 3,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/2.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countComment': 84,
                    'countLike': 67,
                    'isLike': false
                },
                {
                    'id': 4,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/3.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countComment': 84,
                    'countLike': 67,
                    'isLike': false
                },
                {
                    'id': 5,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/4.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countComment': 84,
                    'countLike': 67,
                    'isLike': false
                },
                {
                    'id': 6,
                    'category': 'TECHNOLOGY',
                    'title': 'How might we help people become better-informed citizens?',
                    'image': 'assets/imgs/background/5.jpg',
                    'iconLike': 'heart',
                    'comments': 'chatbubble',
                    'countComment': 84,
                    'countLike': 67,
                    'isLike': false
                }
            ]
        };
    }

    //* Data Set for page 4
    getDataForLayout4 = (): any => {
        return {
            'toolbarTitle': 'Social',
            'slider': [
                {
                  'title': 'Molecule ionic New UI Kit',
                  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                  'image': 'assets/imgs/background/1.jpg',
                  'avatar': 'assets/imgs/avatar/1.jpg',
                  'avatarTitle': 'Grace Palmer',
                  'avatarSubTitle': '29 Articles',
                  'btFollowing': 'Following'
                },
                {
                  'title': 'What’s new in ionic New?',
                  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                  'image': 'assets/imgs/background/2.jpg',
                  'avatar': 'assets/imgs/avatar/2.jpg',
                  'avatarTitle': 'Grace Palmer',
                  'avatarSubTitle': '29 Articles',
                  'btFollowing': 'Following'
                },
                {
                  'title': 'What’s new in ionic New?',
                  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                  'image': 'assets/imgs/background/3.jpg',
                  'avatar': 'assets/imgs/avatar/4.jpg',
                  'avatarTitle': 'Grace Palmer',
                  'avatarSubTitle': '29 Articles',
                  'btFollowing': 'Following'
                },
                {
                  'title': 'What’s new in ionic New?',
                  'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                  'image': 'assets/imgs/background/1.jpg',
                  'avatar': 'assets/imgs/avatar/5.jpg',
                  'avatarTitle': 'Grace Palmer',
                  'avatarSubTitle': '29 Articles',
                  'btFollowing': 'Following'
                }
            ],
            'items': [
                {
                    'id': 1,
                    'title': 'Molecule ionic New UI Kit',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/2.jpg',
                    'avatar': 'assets/imgs/avatar/1.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                },
                {
                    'id': 2,
                    'title': 'What’s new in ionic New?',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/3.jpg',
                    'avatar': 'assets/imgs/avatar/2.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                },
                {
                    'id': 3,
                    'title': 'What’s new in ionic New?',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/1.jpg',
                    'avatar': 'assets/imgs/avatar/3.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                },
                {
                    'id': 4,
                    'title': 'What’s new in ionic New?',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/3.jpg',
                    'avatar': 'assets/imgs/avatar/4.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                },
                {
                    'id': 5,
                    'title': 'What’s new in ionic New?',
                    'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at est purus.',
                    'image': 'assets/imgs/background/1.jpg',
                    'avatar': 'assets/imgs/avatar/5.jpg',
                    'avatarTitle': 'Grace Palmer',
                    'avatarSubTitle': '29 Articles',
                    'btFollowing': 'Following'
                }
            ]
        };
    }

    load(item: any): Observable<any> {
        this.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('wizard/' + item.theme)
                    .valueChanges()
                    .subscribe(snapshot => {
                        this.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        this.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                this.loadingService.hide();
                observer.next(this.getDataForTheme(item));
                observer.complete();
            });
        }
    }
}
