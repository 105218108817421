import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IonCheckbox } from '@ionic/angular';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {

  field: CheckboxInput;

  group: FormGroup;

  values: any[] = []
  value = null
  loadingStarted: boolean = false;
  @ViewChildren('cmp') components: QueryList<IonCheckbox>;

  constructor() { }

  ngOnInit() {
    this.group.get(this.field.name).valueChanges.subscribe(res => {
      ///Precisei adicionar essa variavel de controle, pois conforme for carregado essa info
      ///Ele dispara o change() abaixo.
      if (this.loadingStarted)
        return;


      this.value = res;
      this.loadingStarted = true;
      this.components.toArray().forEach(el => {
        this.value.forEach(val => {
          if (val == el.value) {
            el.checked = true;
          }
        })
      })

      this.loadingStarted = false;
    })
  }

  change(event, name, value, front) {
    event.stopPropagation();
    event.preventDefault();
    console.log(this.field.name, value);
    if (value) {
      this.values.push(name);
    } else {
      if (this.values.includes(name)) {
        this.values.splice(this.values.indexOf(name), 1);
      }
    }
    this.group.get(this.field.name).setValue(this.values)
  }

}
