import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PictureComponent } from './components/picture/picture.component';
import { ButtonHelpComponent } from './components/button-help/button-help.component';
import { IonicModule } from '@ionic/angular';
// import { ExpandableListLayout1Page } from '../components/expandable-list/expandable-list-layout-1/expandable-list-layout-1.page';
import { SplashScreenLayout1Page } from './components/splash-screen-layout-1/splash-screen-layout-1.page';
import { ProgressPieComponent } from './components/progress-pie/progress-pie.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PreviewComponent } from './components/preview/preview.component';
import { PendingTileComponent } from './components/pending-tile/pending-tile.component';
import { ContactModalComponent } from './components/contact-modal/contact-modal.component';
import { DynamicFieldDirective } from './directives/dynamic-field.directive';
import { FormsModuleDynamic } from './components/forms/forms.module';
import { LogoComponent } from './components/logo/logo.component';
import { InvalidModalComponent } from './components/invalid-modal/invalid-modal.component';

@NgModule({
  declarations: [
    PictureComponent,
    ButtonHelpComponent,
    SplashScreenLayout1Page,
    ProgressPieComponent,
    ConfirmDialogComponent,
    LoaderComponent,
    PreviewComponent,
    PendingTileComponent,
    ContactModalComponent,
    LogoComponent,
    InvalidModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModuleDynamic
  ],
  exports: [
    PictureComponent,
    ButtonHelpComponent,
    SplashScreenLayout1Page,
    ProgressPieComponent,
    ConfirmDialogComponent,
    LoaderComponent,
    PendingTileComponent,
    ContactModalComponent,
    FormsModuleDynamic,
    LogoComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
