import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CustomValidators } from '../../components/forms/validators/custom';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  pages: any[];
  
  currentPageBehavior = new BehaviorSubject<string>('0')
  overflow = new BehaviorSubject<string>('scroll')

  constructor() { }

  loadPage(currentPage, pages = []){
    if(pages != []){
      this.pages = pages
    }
    this.currentPageBehavior.next(currentPage)
    
    return this.getCurrentFields()
  }

  getCurrentFields(){
    let fields = []

    fields = this.pages[this.currentPageBehavior.value].elements
    
    return fields
  }

  previousPage(){
    let currentPage = parseInt(this.currentPageBehavior.value)
    if(currentPage > 0){
      this.currentPageBehavior.next((currentPage - 1).toString())
    }
  }

  bindValidations(validations: any, isRequired = false) {
    if (validations.length > 0 || isRequired) {
      const validList = [];

      if (isRequired) {
        validList.push(Validators.required)
      }

      validations.forEach((valid: ValidatorsInput) => {

        if (valid.type == 'text') {
          if (valid.minLength && valid.minLength != 0) {
            validList.push(Validators.minLength(valid.minLength));
          }
          if (valid.maxLength && valid.maxLength != 0) {
            validList.push(Validators.maxLength(valid.maxLength));
          }
          // AllowDigits
          return;
        }

        if (valid.type == 'numeric') {
          if (valid.minValue) {
            validList.push(Validators.min(valid.minValue));
          }
          if (valid.maxValue) {
            validList.push(Validators.max(valid.maxValue));
          }
          return;
        }

        if (valid.type == 'regex') {
          validList.push(CustomValidators.regex(valid.regex));
          return
        }

        validList.push(Validators[valid.type]);

        return
      });

      return Validators.compose(validList);
    }

    return null;
  }
  
}
