import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invalid-link',
  templateUrl: './invalid-link.component.html',
  styleUrls: ['./invalid-link.component.scss'],
})
export class InvalidLinkComponent implements OnInit {

  email: string
  phone: string;
  constructor() { }

  ngOnInit() {
    this.email = JSON.parse(localStorage.getItem('theme'))?.contact?.email ?? '';
    this.phone = JSON.parse(localStorage.getItem('theme'))?.contact?.phone ?? '';
    //document.documentElement.style.setProperty('--bodyColor', '#ffffff');
    //document.documentElement.style.setProperty('--bodyContrast', theme.bodyContrast);
  }

}
