import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { ToastService } from './toast-service';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class TimeLineService implements IService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

    getId = (): string => 'timeline';

    getTitle = (): string => 'Time Line';

    getAllThemes = (): Array<any> => {
        return [
            { 'url': 'timeline/0', 'title': 'Timeline - Left', 'theme': 'layout1' },
            { 'url': 'timeline/1', 'title': 'Timeline - Center', 'theme': 'layout2' },
            { 'url': 'timeline/2', 'title': 'Timeline - Right', 'theme': 'layout3' },
        ];
    }

    getDataForTheme = (menuItem: any): any => {
        return this[
            'getDataFor' +
            menuItem.theme.charAt(0).toUpperCase() +
            menuItem.theme.slice(1)
        ]();
    }

    //* Data Set for page 2
    getDataForLayout1 = (): any => {
        return {
            'toolbarTitle': 'Timeline - Left',
            'items': [
                {
                    'id': 1,
                    'avatar': 'assets/imgs/avatar/10.jpg',
                    'title': 'Theresa Mason',
                    'subtitle': '@theresa',
                },
                {
                    'id': 2,
                    'avatar': 'assets/imgs/avatar/11.jpg',
                    'title': 'Ray Clarke',
                    'subtitle': '@ray',
                },
                {
                    'id': 3,
                    'avatar': 'assets/imgs/avatar/12.jpg',
                    'title': 'Caitlin Wilkinson',
                    'subtitle': '@caitlin',
                },
                {
                    'id': 4,
                    'avatar': 'assets/imgs/avatar/13.jpg',
                    'title': 'Fiona Edwards',
                    'subtitle': '@fiona',
                },
                {
                    'id': 5,
                    'avatar': 'assets/imgs/avatar/1.jpg',
                    'title': 'Ray Clarke',
                    'subtitle': '@ray',
                },
                {
                    'id': 6,
                    'avatar': 'assets/imgs/avatar/2.jpg',
                    'title': 'Caitlin Wilkinson',
                    'subtitle': '@caitlin',
                },
                {
                    'id': 7,
                    'avatar': 'assets/imgs/avatar/7.jpg',
                    'title': 'Juan Lockhart',
                    'subtitle': '@juan',
                },
                {
                    'id': 8,
                    'avatar': 'assets/imgs/avatar/24.jpg',
                    'title': 'Virginia Terry',
                    'subtitle': '@terry',
                },
                {
                    'id': 9,
                    'avatar': 'assets/imgs/avatar/23.jpg',
                    'title': 'George. Blair',
                    'subtitle': '@blara',
                },
                {
                    'id': 10,
                    'avatar': 'assets/imgs/avatar/21.jpg',
                    'title': 'Joan Chenault',
                    'subtitle': '@joan',
                },
                {
                    'id': 11,
                    'avatar': 'assets/imgs/avatar/22.jpg',
                    'title': 'Sandra Fowler',
                    'subtitle': '@sandra',
                },
                {
                    'id': 12,
                    'avatar': 'assets/imgs/avatar/20.jpg',
                    'title': 'Robert J. Eichorn',
                    'subtitle': '@carr',
                },
                {
                    'id': 13,
                    'avatar': 'assets/imgs/avatar/19.jpg',
                    'title': 'Fiona Edwards',
                    'subtitle': '@fiona',
                }

            ]
        };
    }

    //* Data Set for page 2
    getDataForLayout2 = (): any => {
        return {
            'toolbarTitle': 'Timeline - Center',
            'items': [
                {
                    'id': 1,
                    'avatar': 'assets/imgs/avatar/10.jpg',
                    'title': 'Clarke',
                    'subtitle': '@clarke',
                },
                {
                    'id': 2,
                    'avatar': 'assets/imgs/avatar/11.jpg',
                    'title': 'Clarke',
                    'subtitle': '@clarke',
                },
                {
                    'id': 3,
                    'avatar': 'assets/imgs/avatar/12.jpg',
                    'title': 'Caitlin',
                    'subtitle': '@caitlin',
                },
                {
                    'id': 4,
                    'avatar': 'assets/imgs/avatar/13.jpg',
                    'title': 'Fiona',
                    'subtitle': '@fiona',
                },
                {
                    'id': 5,
                    'avatar': 'assets/imgs/avatar/1.jpg',
                    'title': 'Ray',
                    'subtitle': '@ray',
                },
                {
                    'id': 6,
                    'avatar': 'assets/imgs/avatar/2.jpg',
                    'title': 'Caitlin',
                    'subtitle': '@caitlin',
                },
                {
                    'id': 7,
                    'avatar': 'assets/imgs/avatar/15.jpg',
                    'title': 'Sherry',
                    'subtitle': '@carr',
                },
                {
                    'id': 8,
                    'avatar': 'assets/imgs/avatar/18.jpg',
                    'title': 'Despres',
                    'subtitle': '@despres',
                },
                {
                    'id': 9,
                    'avatar': 'assets/imgs/avatar/19.jpg',
                    'title': 'Karen',
                    'subtitle': '@karen',
                },
                {
                    'id': 10,
                    'avatar': 'assets/imgs/avatar/20.jpg',
                    'title': 'Adam',
                    'subtitle': '@rhyta',
                },
                {
                    'id': 11,
                    'avatar': 'assets/imgs/avatar/22.jpg',
                    'title': 'Asher',
                    'subtitle': '@teleworm',
                },
                {
                    'id': 12,
                    'avatar': 'assets/imgs/avatar/21.jpg',
                    'title': 'Thelma',
                    'subtitle': '@rhyta',
                },
                {
                    'id': 13,
                    'avatar': 'assets/imgs/avatar/24.jpg',
                    'title': 'Joyce',
                    'subtitle': '@jourrapide',
                }
            ]
        };
    }

    //* Data Set for page 3
    getDataForLayout3 = (): any => {
        return {
            'toolbarTitle': 'Timeline - Right',
            'items': [
                {
                    'id': 1,
                    'avatar': 'assets/imgs/avatar/10.jpg',
                    'title': 'Theresa Mason',
                    'subtitle': '@theresa',
                },
                {
                    'id': 2,
                    'avatar': 'assets/imgs/avatar/11.jpg',
                    'title': 'Ray Clarke',
                    'subtitle': '@ray',
                },
                {
                    'id': 3,
                    'avatar': 'assets/imgs/avatar/12.jpg',
                    'title': 'Caitlin Wilkinson',
                    'subtitle': '@caitlin',
                },
                {
                    'id': 4,
                    'avatar': 'assets/imgs/avatar/13.jpg',
                    'title': 'Fiona Edwards',
                    'subtitle': '@fiona',
                },
                {
                    'id': 5,
                    'avatar': 'assets/imgs/avatar/1.jpg',
                    'title': 'Ray Clarke',
                    'subtitle': '@ray',
                },
                {
                    'id': 6,
                    'avatar': 'assets/imgs/avatar/2.jpg',
                    'title': 'Caitlin Wilkinson',
                    'subtitle': '@caitlin',
                },
                {
                    'id': 7,
                    'avatar': 'assets/imgs/avatar/16.jpg',
                    'title': 'Erma Grant',
                    'subtitle': '@erma',
                },
                {
                    'id': 8,
                    'avatar': 'assets/imgs/avatar/17.jpg',
                    'title': 'Sherry Adams',
                    'subtitle': '@adams',
                },
                {
                    'id': 9,
                    'avatar': 'assets/imgs/avatar/18.jpg',
                    'title': 'Carolyn Kea',
                    'subtitle': '@carolyn',
                },
                {
                    'id': 10,
                    'avatar': 'assets/imgs/avatar/19.jpg',
                    'title': 'Carrie Hyder',
                    'subtitle': '@carrie',
                },
                {
                    'id': 11,
                    'avatar': 'assets/imgs/avatar/20.jpg',
                    'title': 'David Bishop',
                    'subtitle': '@bishop',
                },
                {
                    'id': 12,
                    'avatar': 'assets/imgs/avatar/24.jpg',
                    'title': 'Tracy Albright',
                    'subtitle': '@tracy',
                },
                {
                    'id': 13,
                    'avatar': 'assets/imgs/avatar/22.jpg',
                    'title': 'Maria Blaine',
                    'subtitle': '@maria',
                },
                {
                    'id': 14,
                    'avatar': 'assets/imgs/avatar/23.jpg',
                    'title': 'Juan Lockhart',
                    'subtitle': '@juan',
                }
            ]
        };
    }

    load(item: any): Observable<any> {
        this.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('timeline/' + item.theme)
                    .valueChanges()
                    .subscribe(snapshot => {
                        this.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        this.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                this.loadingService.hide();
                observer.next(this.getDataForTheme(item));
                observer.complete();
            });
        }
    }
}
