import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { FormService } from 'src/app/shared/services/form/form.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  field:any = {};
  group: FormGroup;
  nestedFields: any = [];
  values:any = {};
  initialized:boolean=false;

  constructor(private fb: FormBuilder, private fs: FormService,  private coreService:CoreService, private loaderService:LoaderService) { 
  }

  ngOnInit() {    
    this.createControl();
  }

  createControl(){
    this.nestedFields = this.field.elements;
    this.nestedFields.forEach(field => {
      field.enableIfResult = true
      field.visibleIfResult = true
      field.requiredIfResult = false
      if (field.hasOwnProperty("requiredIf")) {
        field.requiredIf = field.requiredIf.replaceAll('{', '')
        field.requiredIf = field.requiredIf.replaceAll('}', '')
        field.requiredIf = field.requiredIf.replaceAll("'", '')

        field.requiredIf = field.requiredIf.split(" ")


      }
      if (field.hasOwnProperty("enableIf")) {

        field.enableIf = field.enableIf.replaceAll('{', '')
        field.enableIf = field.enableIf.replaceAll('}', '')
        field.enableIf = field.enableIf.replaceAll("'", '')

        field.enableIf = field.enableIf.split(" ")
        field.enableIfResult = false

      }
      if (field.hasOwnProperty("visibleIf")) {
        if(field.visibleIf.replaceAll){
          field.visibleIf = field.visibleIf.replaceAll('{', '')
          field.visibleIf = field.visibleIf.replaceAll('}', '')
          field.visibleIf = field.visibleIf.replaceAll("'", '')
        }else{

        }
     
        if(field.visibleIf.split){

          field.visibleIf = field.visibleIf.split(" ")
        }else{
          
        }
        field.visibleIfResult = false

      }

      const control = this.fb.control(null, this.fs.bindValidations(field.validators || [], field.isRequired));
      this.group.addControl(field.name, control);
    });
  }
}
