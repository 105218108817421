import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicFormComponent } from 'src/app/shared/components/forms/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {

  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  pages = [
    {
      "name": "page1",
      "elements": [
        {
          "type": "text",
          "name": "inputText",
          "title": "Text",

        },
        {
          "type": "file",
          "name": "pergunta1",
          "maxSize": 0
        }
      ]
    }

    // {
    //  "name": "secondStep",
    //  "elements": [
    //   {
    //    "type": "boolean",
    //    "name": "isRepared",
    //    "title": "O aparelho foi reparado?",
    //    "labelTrue": "Sim",
    //    "labelFalse": "Não"
    //   },
    //   {
    //    "type": "boolean",
    //    "name": "savedParts",
    //    "title": "As peças foram guardados e os salvados preservados?",
    //    "labelTrue": "sim",
    //    "labelFalse": "Nao"
    //   },
    //   {
    //    "type": "text",
    //    "name": "whatsPieceDamaged",
    //    "title": "Qual peça danificada?"
    //   },
    //   {
    //    "type": "dropdown",
    //    "name": "statusDevice",
    //    "title": "O aparelho quando danificado",
    //    "choices": [
    //     {
    //      "value": "N",
    //      "text": "Nao liga "
    //     },
    //     {
    //      "value": "D",
    //      "text": "Com defeito"
    //     }
    //    ]
    //   },
    //   {
    //    "type": "boolean",
    //    "name": "otherEquipmentEnabled",
    //    "title": "Havia outro equipamento danificado ligado ao aparelho em questão?",
    //    "labelTrue": "Sim",
    //    "labelFalse": "Nao"
    //   },
    //   {
    //    "type": "boolean",
    //    "name": "otherEquipmentDamaged",
    //    "title": "Havia outro equipamento danificado ligado na mesma tomada do aparelho em questão?",
    //    "labelTrue": "Sim",
    //    "labelFalse": "nao"
    //   },
    //   {
    //    "type": "boolean",
    //    "name": "defectWay",
    //    "title": "O aparelho em questão já apresentou algum defeito ou foi consertado no passado?",
    //    "labelTrue": "Sim",
    //    "labelFalse": "Nao",
    //    "visibleIf": "{ageDevice} <= 1",


    //    "enableIf": "{pergunta1} <= 2222",


    //    "requiredIf": "{pergunta1} <= 2222",



    //   },
    //   {
    //     "type": "submit",
    //     "name":"submit",
    //     "message": "texto grande no botao de enviar ainda maior do que o texto inserido anteriormente"
    //   }
    //  ]
    // }
  ]



  constructor() { }

  ngOnInit() {

  }

  submit(event) {
  }

}
