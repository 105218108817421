import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class ImageGalleryService implements IService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

    getTitle = (): string => 'Image Gallery';

    getAllThemes = (): Array<any> => {
        return [
            { 'url': 'image-gallery/0', 'title': 'Gallery', 'theme': 'layout1' }
        ];
    }

    getDataForTheme = (menuItem: any): Array<any> => {
        return this[
            'getDataFor' +
            menuItem.theme.charAt(0).toUpperCase() +
            menuItem.theme.slice(1)
        ]();
    }

    // Set Data For Gallery - CATEGORY 1 (ANIMALS)
    getDataForLayout1 = (): any => {
        return {
            'toolbarTitle': 'Gallery',
            'items': [
                {
                    'id': 1,
                    'title': 'Plastic Glass',
                    'image': 'assets/imgs/gallery/full-gallery-content-1/0.jpg',
                    'items': [
                        {
                            'id': 1,
                            'image': 'assets/imgs/gallery/full-gallery-content-1/1.jpg'
                        },
                        {
                            'id': 2,
                            'image': 'assets/imgs/gallery/full-gallery-content-1/2.jpg'
                        },
                        {
                            'id': 3,
                            'image': 'assets/imgs/gallery/full-gallery-content-1/3.jpg'
                        },
                        {
                            'id': 4,
                            'image': 'assets/imgs/gallery/full-gallery-content-1/4.jpg'
                        },
                        {
                            'id': 5,
                            'image': 'assets/imgs/gallery/full-gallery-content-1/5.jpg'
                        },
                        {
                            'id': 6,
                            'image': 'assets/imgs/gallery/full-gallery-content-1/2.jpg'
                        },
                        {
                            'id': 7,
                            'image': 'assets/imgs/gallery/full-gallery-content-1/3.jpg'
                        },
                        {
                            'id': 8,
                            'image': 'assets/imgs/gallery/full-gallery-content-1/5.jpg'
                        }
                    ]
                },
                {
                    'id': 2,
                    'title': 'Ice Cream',
                    'image': 'assets/imgs/gallery/full-gallery-content-2/0.jpg',
                    'items': [
                        {
                            'id': 1,
                            'image': 'assets/imgs/gallery/full-gallery-content-2/1.jpg'
                        },
                        {
                            'id': 2,
                            'image': 'assets/imgs/gallery/full-gallery-content-2/2.jpg'
                        },
                        {
                            'id': 3,
                            'image': 'assets/imgs/gallery/full-gallery-content-2/3.jpg'
                        },
                        {
                            'id': 4,
                            'image': 'assets/imgs/gallery/full-gallery-content-2/4.jpg'
                        },
                        {
                            'id': 5,
                            'image': 'assets/imgs/gallery/full-gallery-content-2/5.jpg'
                        },
                        {
                            'id': 6,
                            'image': 'assets/imgs/gallery/full-gallery-content-2/6.jpg'
                        },
                        {
                            'id': 7,
                            'image': 'assets/imgs/gallery/full-gallery-content-2/7.jpg'
                        },
                        {
                            'id': 8,
                            'image': 'assets/imgs/gallery/full-gallery-content-2/1.jpg'
                        }
                    ]
                },
                {
                    'id': 3,
                    'title': 'Fruits',
                    'image': 'assets/imgs/gallery/full-gallery-content-3/0.jpg',
                    'items': [
                        {
                            'id': 1,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/1.jpg'
                        },
                        {
                            'id': 2,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/2.jpg'
                        },
                        {
                            'id': 3,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/3.jpg'
                        },
                        {
                            'id': 4,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/4.jpg'
                        },
                        {
                            'id': 5,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/5.jpg'
                        },
                        {
                            'id': 6,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/6.jpg'
                        },
                        {
                            'id': 7,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/1.jpg'
                        },
                        {
                            'id': 8,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/4.jpg'
                        },
                        {
                            'id': 9,
                            'image': 'assets/imgs/gallery/full-gallery-content-3/2.jpg'
                        }
                    ]
                },
                {
                    'id': 4,
                    'title': 'Cosmetics',
                    'image': 'assets/imgs/gallery/full-gallery-content-4/0.jpg',
                    'items': [
                        {
                            'id': 1,
                            'image': 'assets/imgs/gallery/full-gallery-content-4/1.jpg'
                        },
                        {
                            'id': 2,
                            'image': 'assets/imgs/gallery/full-gallery-content-4/2.jpg'
                        },
                        {
                            'id': 3,
                            'image': 'assets/imgs/gallery/full-gallery-content-4/3.jpg'
                        },
                        {
                            'id': 4,
                            'image': 'assets/imgs/gallery/full-gallery-content-4/4.jpg'
                        },
                        {
                            'id': 5,
                            'image': 'assets/imgs/gallery/full-gallery-content-4/5.jpg'
                        },
                        {
                            'id': 6,
                            'image': 'assets/imgs/gallery/full-gallery-content-4/6.jpg'
                        },
                        {
                            'id': 7,
                            'image': 'assets/imgs/gallery/full-gallery-content-4/7.jpg'
                        }
                    ]
                },
                {
                    'id': 5,
                    'title': 'Skateboard',
                    'image': 'assets/imgs/gallery/full-gallery-content-5/0.jpg',
                    'items': [
                        {
                            'id': 1,
                            'image': 'assets/imgs/gallery/full-gallery-content-5/1.jpg'
                        },
                        {
                            'id': 2,
                            'image': 'assets/imgs/gallery/full-gallery-content-5/2.jpg'
                        },
                        {
                            'id': 3,
                            'image': 'assets/imgs/gallery/full-gallery-content-5/3.jpg'
                        },
                        {
                            'id': 4,
                            'image': 'assets/imgs/gallery/full-gallery-content-5/4.jpg'
                        },
                        {
                            'id': 5,
                            'image': 'assets/imgs/gallery/full-gallery-content-5/5.jpg'
                        },
                        {
                            'id': 6,
                            'image': 'assets/imgs/gallery/full-gallery-content-5/6.jpg'
                        },
                        {
                            'id': 7,
                            'image': 'assets/imgs/gallery/full-gallery-content-5/7.jpg'
                        },
                        {
                            'id': 8,
                            'image': 'assets/imgs/gallery/full-gallery-content-5/8.jpg'
                        }
                    ]
                },
                {
                    'id': 6,
                    'title': 'Donuts',
                    'image': 'assets/imgs/gallery/full-gallery-content-6/0.jpg',
                    'items': [
                        {
                            'id': 1,
                            'title': 'Eagles 1',
                            'image': 'assets/imgs/gallery/full-gallery-content-6/1.jpg'
                        },
                        {
                            'id': 2,
                            'title': 'Eagles 2',
                            'image': 'assets/imgs/gallery/full-gallery-content-6/2.jpg'
                        },
                        {
                            'id': 3,
                            'title': 'Eagles 3',
                            'image': 'assets/imgs/gallery/full-gallery-content-6/3.jpg'
                        },
                        {
                            'id': 4,
                            'title': 'Eagles 4',
                            'image': 'assets/imgs/gallery/full-gallery-content-6/4.jpg'
                        },
                        {
                            'id': 5,
                            'title': 'Eagles 5',
                            'image': 'assets/imgs/gallery/full-gallery-content-6/5.jpg'
                        },
                        {
                            'id': 6,
                            'title': 'Eagles 6',
                            'image': 'assets/imgs/gallery/full-gallery-content-6/6.jpg'
                        },
                        {
                            'id': 7,
                            'title': 'Eagles 7',
                            'image': 'assets/imgs/gallery/full-gallery-content-6/7.jpg'
                        }
                    ]
                }
            ]
        };
    }

    load(item: any): Observable<any> {
        const that = this;
        that.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('imageGallery/' + item.theme)
                    .valueChanges()
                    .subscribe(snapshot => {
                        that.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        that.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                that.loadingService.hide();
                observer.next(this.getDataForTheme(item));
                observer.complete();
            });
        }
    }
}
