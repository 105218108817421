import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-device',
  templateUrl: './not-device.component.html',
  styleUrls: ['./not-device.component.scss'],
})
export class NotDeviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
