import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {

  @Input() title: string = '';
  @Input() downloadUrl: string = '';
  @Input() isVideo: boolean = false;

  constructor(
    private modalController: ModalController, private loadService: LoaderService) { }

  ngOnInit() { 
    this.loadService.isLoading.next(false);
  }

  dismissModal() {
    this.modalController.dismiss();
  }

}
