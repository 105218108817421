import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {

  @Input() messageTitle: string;
  @Input() messageContent: string;
  @Input() cancelButtonText = '';
  @Input() submitButtonText = 'CONTINUAR';
  @Input() type = 'danger';
  @Input() contentRef: any;
  @Input() disableButton: boolean = false;

  @Output() confirmResult: EventEmitter<boolean>;

  constructor(private utilsService: UtilsService) { }

  ngOnInit() {
    this.confirmResult = new EventEmitter();
    if (this.disableButton) {
      setTimeout(() => {
        this.submitCallback();
      }, 3000);
    } else {
      this.confirmResult = new EventEmitter();
    }
  }

  closeModal() {
    this.confirmAndClose(false);
  }

  submitCallback() {
    this.confirmAndClose(true);
  }

  private confirmAndClose(value: boolean) {
    this.utilsService.setConfirm(value)
    this.utilsService.closeModal(this.contentRef);
  }

}
