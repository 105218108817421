
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgModule, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VideoService } from 'src/app/services/video.service';

import { Capacitor, Plugins } from '@capacitor/core'
import * as WebVPPlugin from 'capacitor-video-player'
import { ModalController } from '@ionic/angular';
import { CoreService } from 'src/app/shared/services/core.service';
import { FirebaseStorageService } from 'src/app/core/services/firebase-storage.service';
import { AngularFireStorageReference } from '@angular/fire/storage/ref';
import { InspectionService } from 'src/app/core/services/inspection.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { LoaderService } from 'src/app/core/services/loader.service';
import { DataResult } from 'src/app/core/models/gclaims.model';

const { CapacitorVideoPlayer } = Plugins;

@Component({
    selector: 'app-video-signature',
    templateUrl: 'video-signature.page.html',
    styleUrls: ['video-signature.page.scss'],
})
export class VideoSignature implements AfterViewInit, OnDestroy, OnInit {
    mediaRecorder: MediaRecorder;
    videoPlayer: any;
    isRecording = false;
    videos = [];
    videoIsEmpty = true;
    chunks = []
    videoPlayed: boolean = false;
    private resultSubscribe: any;
    @ViewChild('video') captureElement: ElementRef<HTMLVideoElement>;
    video = document.querySelector('video')


    pending: boolean = false;
    pendingDescription: string = '';
    inspectionData: DataResult;
    policyNumber: string | number = '';

    constructor(
        private videoService: VideoService,
        private coreService: CoreService,
        private firebaseStorageService: FirebaseStorageService,
        private inspectionService: InspectionService,
        private utilsService: UtilsService,
        private loaderService: LoaderService,
    ) {

    }

    ngOnInit(): void {
        this.inspectionData = this.coreService.getCurrentInspectionData;
        this.policyNumber = this.inspectionData.PolicyNumber;
    }

    ngOnDestroy(): void {
        this.delete();
    }

    async ngAfterViewInit() {
        this.delete();
        const pendingMap: Map<any, any> = this.coreService.mountPendingMap();
        this.pending = pendingMap.has('Subscription')
        this.pendingDescription = pendingMap.get('Subscription') ?? ''

        this.videos = await this.videoService.loadVideos();
        this.videoIsEmpty = this.videos.length == 0 ? true : false
        if (Capacitor.isNative) {
            this.videoPlayer = CapacitorVideoPlayer;
        } else {
            this.videoPlayer = WebVPPlugin.CapacitorVideoPlayer
        }
        this.video = document.querySelector('video')
    }

    async recordVideo() {
        this.chunks = []
        let stream
        try {
            stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'user'
                },
                audio: true
            })

        } catch (error) {

        }
        if (stream != undefined) {
            this.captureElement.nativeElement.srcObject = stream;
            this.isRecording = true;

            const options = { mimeType: 'video/ogg' }
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.start()

            let self = this;
            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data && event.data.size > 0) {
                    self.chunks.push(event.data)
                }
            }

            this.mediaRecorder.addEventListener('stop', async (event) => {
                const videoBuffer = new Blob(self.chunks, { type: 'video/mp4' });

                await self.videoService.storeVideo(videoBuffer)

                self.videos = self.videoService.videos;
                self.videoIsEmpty = false;
            })

        } else {
            const result$ = this.utilsService.showConfirmDialog(`Oops...`, 'Precisamos de acesso a câmera e microfone!', false, 'danger', '', 'OK');
            localStorage.setItem('camPermission','false')
    
            this.unsubscribeResult();

            this.resultSubscribe = result$.asObservable().pipe(distinctUntilChanged())
                .subscribe(success => {
                    this.utilsService.setConfirm(false)
                })
        }
    }

    delete() {
        this.videoIsEmpty = true
        this.videos = []
        this.videoService.deleteVideo()
    }

    cancel() {
        this.videoIsEmpty = true
        this.videos = []
        this.videoService.deleteVideo()
        
        this.coreService.navigate('choose-method');
        
    }

    stopRecord() {
        this.mediaRecorder.stop()
        this.captureElement.nativeElement.srcObject = null;
        this.captureElement.nativeElement.src = null;
        this.isRecording = false
        this.mediaRecorder = null
    }

    async play(video) {
        const base64data = await this.videoService.getVideoUrl(video)
        const full = document.getElementById('fullscreen')
        full.hidden = false;
        await this.videoPlayer.initPlayer({
            mode: "fullscreen",
            url: base64data,
            playerId: "fullscreen",
            componentTag: 'app-video-signature'
        })
        this.videoPlayed = true;
    }

    async send() {
        this.loaderService.isLoading.next(true);
        const video = await this.videoService.getVideoUrl(this.videos[0])
        const uuid = this.coreService.generateUUID();
        const base64Response = await fetch(video);
        const blob = await base64Response.blob();
        const payload = {
            name: uuid,
            archive: blob
        }
        let { task, ref } = this.firebaseStorageService.uploadFile(this.coreService.inspectionUniqueId.value, payload)
        if ((await task).state == 'success') {
            this.getDownloadUrl(ref, uuid, base64Response);
            this.loaderService.isLoading.next(false)
            this.delete();
        }
    }

    async cancelVideo() {
        await this.videoPlayer.stopAllPlayers();
        const full = document.getElementById('fullscreen')
        full.hidden = true;
        this.videoPlayed = false;
    }


    private getDownloadUrl(ref: AngularFireStorageReference, uuid: string, base64: Response) {
        ref.getDownloadURL().subscribe(downloadUrl => {
            const signature = {
                uuid,
                downloadUrl,
                type: 'video/mp4',
                pendingCompleted: false,
                content: base64.url
            }
            if (this.pending) {
                signature.pendingCompleted = true
                this.inspectionService.updateInspection({ signature });
                this.coreService.navigate('pending/dashboard');
            } else {
                let tzoffset = (new Date()).getTimezoneOffset() * 60000;
                this.inspectionService.updateInspection({ signature, endDate: new Date(Date.now() - tzoffset).toISOString() });
                this.coreService.navigate('vistory-complete-successfull');
            }
        })
    }

    private unsubscribeResult() {
        if (this.resultSubscribe != undefined) { this.resultSubscribe.unsubscribe() }
    };
}