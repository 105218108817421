export const AppSettings = {
    'IS_FIREBASE_ENABLED': false,
    'SHOW_START_WIZARD': false,
    'SUBSCRIBE': false,
    'FIREBASE_CONFIG': {
      'apiKey': "AIzaSyBOqvMAf_TESQ4hUibs2hGWXuAn1Y6vSLQ",
      'authDomain': "ionic-molecule-devspush.firebaseapp.com",
      'databaseURL': "https://ionic-molecule-devspush.firebaseio.com",
      'projectId': "ionic-molecule-devspush",
      'storageBucket': "ionic-molecule-devspush.appspot.com",
      'messagingSenderId': "30004979587",
      'appId': "1:30004979587:web:b7e88554a165eb3bc478b6",
      'measurementId': "G-3KZS2RJNY1"
    }
};
