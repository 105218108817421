import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from 'src/app/shared/services/form/form.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {

  field: DropdownInput;

  group: FormGroup;

  constructor(private fs:FormService) { }

  ngOnInit() {
    if (!this.field.enableIfResult) {
      this.group.get(this.field.name).disable({ emitEvent: false });
    } else {
      this.group.get(this.field.name).enable({ emitEvent: false })
    }
  }

  @HostListener('document:click', ['$event'])
  click() {
    let result: any = document.querySelectorAll('.ng-dropdown-panel')
    if (result.length > 0){
      this.fs.overflow.next('hidden')
    } else {
      this.fs.overflow.next('scroll')
    }
    
  }  
}
