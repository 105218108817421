import { IService } from './IService';
import { AngularFireDatabase } from '@angular/fire/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from './app-settings';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class ListViewSwipeToDismissService implements IService {

    constructor(public af: AngularFireDatabase, private loadingService: LoadingService) { }

    getId = (): string => 'swipeToDismiss';

    getTitle = (): string => 'Swipe to dismiss';

    getAllThemes = (): Array<any> => {
        return [
            { 'url': 'swipe-to-dismiss/0', 'title': 'Swipe Button', 'theme': 'layout1' },
            { 'url': 'swipe-to-dismiss/1', 'title': 'Swipe Icons', 'theme': 'layout2' }
        ];
    }

    getDataForTheme = (menuItem: any): Array<any> => {
        return this[
            'getDataFor' +
            menuItem.theme.charAt(0).toUpperCase() +
            menuItem.theme.slice(1)
        ]();
    }

    //* Data Set for page 1
    getDataForLayout1 = (): any => {
        return {
            'headerTitle': 'Swipe Button',
            'items': [
                {
                    'id': 1,
                    'title': 'Lauren Simpson',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/0.jpg'
                },
                {
                    'id': 2,
                    'title': 'Russell Gutierrez',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/1.jpg'
                },
                {
                    'id': 3,
                    'title': 'Theresa Day',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/2.jpg'
                },
                {
                    'id': 4,
                    'title': 'Kathleen Simmons',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/3.jpg'
                },
                {
                    'id': 5,
                    'title': 'Betty Estrada',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/4.jpg'
                },
                {
                    'id': 6,
                    'title': 'Alice Newman',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/5.jpg'
                },
                {
                    'id': 7,
                    'title': 'Theresa  Ferguson',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/6.jpg'
                },
                {
                    'id': 8,
                    'title': 'Fiona Simpson',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/7.jpg'
                },
                {
                    'id': 9,
                    'title': 'Russell  King',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/8.jpg'
                },
                {
                    'id': 10,
                    'title': 'Theresa  Mitchell',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/9.jpg'
                },
                {
                    'id': 11,
                    'title': 'Sophie Gutierrez',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/10.jpg'
                },
                {
                    'id': 12,
                    'title': 'Russell Gutierrez',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/11.jpg'
                },
                {
                    'id': 13,
                    'title': 'Sophie Gutierrez',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/12.jpg'
                },
                {
                    'id': 14,
                    'title': 'Ryan Mitchell',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/13.jpg'
                }
            ]
        };
    }

    //* Data Set for page 2
    getDataForLayout2 = (): any => {
        return {
            'headerTitle': 'Swipe Icons',
            'items': [
                {
                    'id': 1,
                    'title': 'Lauren Simpson',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/0.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 2,
                    'title': 'Russell Gutierrez',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/1.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 3,
                    'title': 'Theresa Day',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/2.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 4,
                    'title': 'Kathleen Simmons',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/3.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 5,
                    'title': 'Betty Estrada',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/4.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 6,
                    'title': 'Alice Newman',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/5.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 7,
                    'title': 'Theresa  Ferguson',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/6.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 8,
                    'title': 'Fiona Simpson',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/7.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 9,
                    'title': 'Russell  King',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/8.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 10,
                    'title': 'Theresa  Mitchell',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/9.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 11,
                    'title': 'Sophie Gutierrez',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/10.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 12,
                    'title': 'Russell Gutierrez',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/11.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 13,
                    'title': 'Sophie Gutierrez',
                    'image': 'assets/imgs/avatar/12.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                },
                {
                    'id': 14,
                    'title': 'Ryan Mitchell',
                    'button': 'Message',
                    'image': 'assets/imgs/avatar/13.jpg',
                    'iconClose': 'close-circle',
                    'iconAdd': 'add-circle'
                }
            ]
        };
    }

    load(item: any): Observable<any> {
        const that = this;
        that.loadingService.show();
        if (AppSettings.IS_FIREBASE_ENABLED) {
            return new Observable(observer => {
                this.af
                    .object('listView/swipeToDismiss/' + item.theme)
                    .valueChanges()
                    .subscribe(snapshot => {
                        that.loadingService.hide();
                        observer.next(snapshot);
                        observer.complete();
                    }, err => {
                        that.loadingService.hide();
                        observer.error([]);
                        observer.complete();
                    });
            });
        } else {
            return new Observable(observer => {
                that.loadingService.hide();
                observer.next(this.getDataForTheme(item));
                observer.complete();
            });
        }
    }
}
