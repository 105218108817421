import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, DocumentChangeAction, DocumentReference } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { CoreService } from 'src/app/shared/services/core.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {
  inspectionId: string

  constructor(private firestore: AngularFirestore, private coreService: CoreService) {
    this.coreService.inspectionUniqueId.subscribe(uniqueId => {
      this.inspectionId = uniqueId
    })
  }

  getInspectionSnapshot(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection('inspection').snapshotChanges();
  }

  getInspection(): Observable<firebase.firestore.QuerySnapshot<unknown>> {
    return this.firestore.collection('inspection').get();
  }

  getInspectionById(): Observable<firebase.firestore.DocumentSnapshot<unknown>> {
    console.log(this.inspectionId)
    return this.firestore.collection('inspection').doc(this.inspectionId).get();
  }

  createInspection(lastPageUrl: string, payload: any): Observable<void> {
    let tzoffset = (new Date()).getTimezoneOffset() * 60000;

    return from(this.firestore.collection('inspection').doc(this.inspectionId).set({
      lastPageUrl,
      startDate: new Date(Date.now() - tzoffset).toISOString(),
      endDate: null,
      ...this.coreService.user.value,
      ...payload
    })
    );
  }

  setAddress(lat: number, long: number): Observable<void> {

    return from(this.firestore.collection('inspection').doc(this.inspectionId).set({
      long,
      lat
    }, { merge: true }));
  }

  getRefInscpetion(): CollectionReference {
    return this.firestore.collection('inspection').ref;
  }

  deleteInspection(): Observable<void> {
    return from(this.firestore.collection('inspection').doc(this.inspectionId).delete());
  }

  updateInspection(payload: any): Observable<void> {
    return from(this.firestore.collection('inspection').doc(this.inspectionId).update(payload));
  }

  createCollection(collection: string, path: string, payload: any): Observable<void> {
    return from(this.firestore.collection('inspection').doc(this.inspectionId).collection(collection).doc(path).set({
      ...payload
    })
    );
  }

  updateCollection(collection: string, path: string, payload: any) {
    return from(this.firestore.collection('inspection').doc(this.inspectionId).collection(collection).doc(path).update({
      ...payload
    }));
  }

  getCollection(collection: string): Observable<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
    return this.firestore.collection('inspection').doc(this.inspectionId).collection(collection).get();
  }

}
