import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFireStorageReference } from '@angular/fire/storage';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { FirebaseStorageService } from 'src/app/core/services/firebase-storage.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { PhotoService } from 'src/app/shared/services/photo.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { PreviewComponent } from '../../preview/preview.component';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
  @Input() label: string = ''
  @Input() labelColorHex: string = ''
  @Input() min: string = ''
  @Input() max: string = ''
  @Input() containerPictureOpacity: '' = '';
  @Input() listPicture: any[] = [];
  @Input() hasAccessToMakeVideo: any = undefined;
  
  @Output() onUpload = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();

  @ViewChild('inputFile') inputFile: any;
  @ViewChild('uploadButton') uploadButton: any;

  field: FileInput;
  group: FormGroup;

  disable: boolean = false
  uuidGenerated: any;
  downloadUrl: string;
  private resultSubscribe: Subscription;

  constructor(
    private photoService: PhotoService,
    private coreService: CoreService,
    private firebaseStorageService: FirebaseStorageService,
    private loaderService: LoaderService,
    private modalController: ModalController,
    private utilsService: UtilsService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewChecked(): void {
    this.enableDisable()
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.buttonUpload(this.inputFile.el)
    this.buttonUpload(this.uploadButton.el);

    //Foi adicionado aqui também esse set inicial, pois quando carrega a informação, não é disparado
    //o valueChanges do ngOnInit();
    let pictures = this.group.get(this.field.name).value || [];
    this.listPicture = pictures;
  }

  ngOnInit() {
    this.group.get(this.field.name).valueChanges.subscribe(res => {
      this.listPicture = res
    })
  }
  enableDisable() {
    if (this.listPicture?.length == parseInt(this.max)) {
      this.disable = true
    } else {
      this.disable = false

    }
  }

  buttonUpload(element: any) {
    let el = element;
    if (typeof el.length === 'undefined') {
      el = [el]
    }
    el.forEach((domNode, i) => {
      let input;
      if (domNode.tagName === 'INPUT' && domNode.type === 'file') {
        input = domNode
      } else {
        input = document.createElement('input')
        input.setAttribute('type', 'file');
        if (this.field?.acceptedTypes) {
          input.setAttribute('accept', this.field?.acceptedTypes);
        }
        input.setAttribute('id', i);
        input.setAttribute('multiple', "");
        input.style.visibility = 'hidden';
        input.style.position = 'absolute';
        domNode.appendChild(input);
        domNode.addEventListener('click', ev => {
          input.click();
        }, false)

        input.addEventListener('change', ev => {
          this.upload(ev.target.files[0]);
        })
      }
    });
  }

  private upload(file: any) {
    this.photoService.convertFileToBase64(file)
      .subscribe(async res => {
        this.uuidGenerated = '';
        this.uuidGenerated = this.coreService.generateUUID();
        await this.uploadToStorage(this.uuidGenerated, res);
      })
  }

  toggleDescription(index: number, description?: string) {
    this.listPicture[index].enableDescription = !this.listPicture[index].enableDescription;
    if (description !== undefined) {
      this.listPicture[index].description = description;
      this.emitUpload();
    }
  }

  removePicture(index: number, uuid: string) {
    const result$ = this.utilsService.showConfirmDialog(`Remover foto`, 'Deseja continuar?', false, 'danger', 'NÃO', 'REMOVER');

    this.resultSubscribe = result$.asObservable().pipe(
      take(2),
      distinctUntilChanged()
    ).subscribe(success => {
      if (success) {
        this.firebaseStorageService.deleteFileFromStorage(this.coreService.inspectionUniqueId.value, uuid)
        this.utilsService.setConfirm(false)
        this.listPicture.splice(index, 1);
        this.onDelete.emit(this.listPicture);
        this.enableDisable()
      }
    })
  }

  emitUpload() {
    this.enableDisable()
    this.onUpload.emit(this.listPicture);
    this.loaderService.isLoading.next(false)
    this.group.get(this.field.name).setValue(this.listPicture);
  }

  async uploadToStorage(uuid: string, file: any) {
    //base64String
    this.loaderService.isLoading.next(true);
    const base64Response = await fetch(`${file}`);
    const blob = await base64Response.blob();
    const payload = {
      name: uuid,
      archive: blob
    }
    let { task, ref } = this.firebaseStorageService.uploadFile(this.coreService.inspectionUniqueId.value, payload)
    if ((await task).state == 'success') {
      this.getDownloadUrl(ref, file, base64Response);
    }
  }

  async presentModal(uuid: string, format: string, downloadUrl) {
    const name = `${uuid.slice(0, 7)}.${format}`;
    const modal = await this.modalController.create({
      component: PreviewComponent,
      componentProps: {
        'title': name,
        'downloadUrl': downloadUrl
      }
    });
    return await modal.present();
  }

  private getDownloadUrl(ref: AngularFireStorageReference, result: any, base64: Response) {
    this.downloadUrl = '';
    ref.getDownloadURL().subscribe(downloadUrl => {
      this.downloadUrl = downloadUrl;
      this.listPicture.push({
        uuid: this.uuidGenerated,
        enableDescription: false,
        description: '',
        downloadUrl: downloadUrl,
        content: this.uuidGenerated,
        format: this.getFormat(result),
        type: 'picture_uploaded'
      })
      this.emitUpload();
    })
  }

  private getFormat(image: string) {
    return image.substring(image.indexOf('/') + 1).split(';')[0];
  }

  private unsubscribeResult() {
    if (this.resultSubscribe != undefined) {
      this.resultSubscribe.unsubscribe()
    }
  };

}
