import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactModalComponent } from 'src/app/shared/components/contact-modal/contact-modal.component';

@Component({
  selector: 'app-vistory-expirated',
  templateUrl: './vistory-expirated.component.html',
  styleUrls: ['./vistory-expirated.component.scss'],
})
export class VistoryExpiratedComponent implements OnInit {

  theme:any={};
  email: string
  phone: string;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.email = JSON.parse(localStorage.getItem('theme'))?.contact?.email ?? '';
    this.phone = JSON.parse(localStorage.getItem('theme'))?.contact?.phone ?? '';
  }

  async showModal() {
    const modal = await this.modalController.create({
      component: ContactModalComponent,
      cssClass: 'modal-custom-contact',
    })

    return await modal.present();
  }


}
