import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn:'any'
})
export class PercentFormEquipmentService {
    
    constructor(){this.percentFormEquipment = new BehaviorSubject<string>("0")}
    percentFormEquipment: BehaviorSubject<string>


    showPercentFormEquipment(progress : string){
        this.percentFormEquipment.next(progress);
    }

}