import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Jsonp } from '@angular/http/src/http';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { DataResult } from 'src/app/core/models/gclaims.model';
import { EquipmentService } from 'src/app/core/services/equipment.service';
import { InspectionService } from 'src/app/core/services/inspection.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { PreviewComponent } from 'src/app/shared/components/preview/preview.component';
import { CoreService } from 'src/app/shared/services/core.service';
import { UploadPhotoLocalPage } from '../initial/pages/upload-photo-local/upload-photo-local.page';
import { propertiesEquipment } from "./view-details.config";


interface testeItems {
  name: string,
  value: string
}
interface teste {
  name: string,
  items: testeItems[]
}
interface itemVistory {
  whatsPieceDamaged: string,
  whoDiscarded: string,
  whenDiscardedDay: string,
  whenDiscardedMonth: string,
  whenDiscardedYear: string,
  statusDevice: string,
  whatsDefect: string,
  isDamaged: string,
  whatHappenedWithDevice: string,
  savedParts: string,
  otherEquipmentEnabled: string,
  otherEquipmentDamaged: string,
  defectTime: string,
  defectWay: string,
  turnLigth: string,
  carbonization: string,
  typeDevice: string,
  reportAboutDamage: string,
  ageDevice: string,
  monthYear: string,
  pictureEnviroment: string,
  isCloseOfLocal: string
}
interface data {
  id: string,
  section: string,
  title: string,
  icon: string,
  items: {}[]

}

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss'],
  providers: []
})


export class ViewDetailsComponent implements OnInit, OnDestroy {

  @Output() onItemClick = new EventEmitter();

  equipmentId: string = '0';
  propertiesEquipment = JSON.parse(JSON.stringify(propertiesEquipment));
  propertiesEquipmentKeys = Object.keys(propertiesEquipment[0])
  firstStep = []
  secondStep = []

  expande: boolean = false
  itemsVistory: itemVistory[] = []
  shownGroup: null
  isLoading: boolean = false;

  type: string;
  inspectionData: DataResult;
  data: data[] = [{
    'id': '0',
    'section': 'mainData',
    'title': 'Dados Principais',
    'icon': '../../../assets/easy-inspect/images/icon-main-data.svg',
    'items': []
  },
  {
    'id': '1',
    'section': 'pictures',
    'title': 'Fotos',
    'icon': '../../../assets/easy-inspect/images/icon-picture.svg',
    'items': []
  },
  {
    'id': '2',
    'section': 'details',
    'title': 'Detalhamento do Aparelho',
    'icon': '../../../assets/easy-inspect/images/icon-details.svg',
    'items': null
  }]
  pieces = [{
    'whatsPieceDamaged': 'Bateria',
    'whoDiscarded': 'quem descartou',
    'whenDiscardedDay': 'em que dia foi discardado',
    'whenDiscardedMonth': 'em que mes foi discartado',
    'whenDiscardedYear': 'em que ano foi discartado',
    'statusDevice': 'D',
    'whatsDefect': 'qual defeito',
    'isDamaged': true,
    'whatHappenedWithDevice': 'o que aconteceu com o dispositivo',
    'savedParts': true,
    'otherEquipmentEnabled': false,
    'otherEquipmentDamaged': false,
    'defectTime': 'tempo de defeito',
    'defectWay': true,
    'turnLigth': false,
    'carbonization': false,
    'isRepared': false,
    'typeDevice': 'Lavadora',
    'reportAboutDamage': 'O aparelho estava na tomada quando a luz caiu',
    'ageDevice': '1',
    'monthYear': 'ano',
    'pictureEnviroment': 'foto do ambiente',
    'isCloseOfLocal': false
  }]
  groups: teste[] = [
    {
      'name': '1',
      'items': [
        { 'name': '1', 'value': '2' },
        { 'name': '2', 'value': '2' }
      ]
    },
    {
      'name': '2',
      'items': [
        { 'name': '1', 'value': '2' }
      ]
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private equipmentService: EquipmentService,
    private coreSerice: CoreService,
    private inspectionService: InspectionService,
    private coreService: CoreService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private loadService: LoaderService
  ) { }

  ngOnDestroy(): void {
    this.propertiesEquipment = []
    this.propertiesEquipmentKeys = []
  }

  isType(item) {
    return item === parseInt(this.type, 10);
  }

  // events
  onItemClickFunc(item, event) {
    if (event) {
      event.stopPropagation();
    }
    this.onItemClick.emit(item);
  }



  toggleGroup(group: any) {
    if (event) {
      event.stopPropagation();
    }
    group.show = !group.show;
  }

  goBack() {
    this.coreSerice.navigate('dashboard')
  }

  ngOnInit() {
    this.propertiesEquipment = JSON.parse(JSON.stringify(propertiesEquipment));
    this.propertiesEquipmentKeys = Object.keys(propertiesEquipment[0]);
    this.getEquipment();
    this.getPhotos();
    this.getMainData();

    this.inspectionData = this.coreSerice.getCurrentInspectionData;
  }

  prepareItem(itemsVistory) {

    itemsVistory.forEach(item => {
      item.savedParts = item.savedParts == true ? 'SIM' : 'NÃO'
      item.carbonization = item.carbonization == true ? 'SIM' : 'NÃO'
      item.turnLigth = item.turnLigth == true ? 'SIM' : 'NÃO'
      item.otherEquipmentEnabled = item.otherEquipmentEnabled == true ? 'SIM' : 'NÃO'
      item.otherEquipmentDamaged = item.otherEquipmentDamaged == true ? 'SIM' : 'NÃO'
      item.isDamaged = item.isDamaged == true ? 'SIM' : 'NÃO'
      item.defectWay = item.defectWay == true ? 'SIM' : 'NÃO'
      item.isCloseOfLocal = item.isCloseOfLocal == true ? 'SIM' : 'NÃO'

      this.itemsVistory.push(item)
      this.data[2].items = item
    });


  }

  private getEquipment() {
    this.route.queryParams.pipe(take(1))
      .subscribe(params => {


        this.equipmentId = this.route.snapshot.params.id;


        // if (this.equipmentId != 'undefined' && this.equipmentId != '0') {
        //   this.equipmentService.getEquipmentById(this.equipmentId).pipe(take(1))
        //     .subscribe(res => {
        //       const data = res.data();



        //       this.firstStep = Object.entries(data.firstStep).map(([key, value]) => { return { key, value } })
        //       this.secondStep = Object.entries(data.secondStep).map(([key, value]) => { return { key, value } })
        //       Object.entries(data.firstStep).map(([key, value]) => {

        //         // this.buildPictureList(key,value)
        //         for (const property of this.propertiesEquipment) {
        //           if (property[key] != undefined) {
        //             if (value == null) {
        //               property[key].disabled = true;
        //             }
        //             property[key].value = value;
        //           }
        //         }
        //       })
        //       Object.entries(data.secondStep).map(([key, value]) => {
        //         for (const property of this.propertiesEquipment) {
        //           if (property[key] != undefined) {
        //             if (value == null) {
        //               property[key].disabled = true;
        //             }
        //             property[key].value = value;
        //           }
        //         }
        //       })

        //     }, () => { }, () => {
        //       setTimeout(() => {
        //         this.mountDetails();
        //       }, 500);
        //     })
        // }
      })
  }

  private mountDetails() {
    this.isLoading = false;
    for (const item of this.firstStep) {
      if (item.key == 'monthYear') {
        let val = '';
        if (item.value == true) {
          val = this.propertiesEquipment[0].ageDevice.value == '1' ? 'MÊS' : 'MESES'
        } else if (item.value == false) {
          val = this.propertiesEquipment[0].ageDevice.value == '1' ? 'ANO' : 'ANOS'
        }

        this.propertiesEquipment[0].ageDevice.value = `${this.propertiesEquipment[0].ageDevice.value} ${val}`
      }
      if (item.key == 'isCloseOfLocation') {
        this.propertiesEquipment[0].isCloseOfLocation.value = this.propertiesEquipment[0].isCloseOfLocation.value == 'true' || this.propertiesEquipment[0].isCloseOfLocation.value == true ? 'SIM' : 'NÃO'
      }
      if (item.key == 'pictureEnviroment' || item.key == 'pictureModelBrand' || item.key == 'pictureDevice') {
        this.data[1]['items'].push({ label: this.propertiesEquipment[0][item.key].label, value: item.value });
        this.propertiesEquipment[0][item.key].disabled = true;
      }
    }
    for (const item of this.secondStep) {
      if (item.key == 'isRepared') {
        this.propertiesEquipment[0].isRepared.value = this.propertiesEquipment[0].isRepared.value == 'true' || this.propertiesEquipment[0].isRepared.value == true ? 'SIM' : 'NÃO'
      }
      if (item.key == 'savedParts') {
        this.propertiesEquipment[0].savedParts.value = this.propertiesEquipment[0].savedParts.value == 'true' || this.propertiesEquipment[0].savedParts.value == true ? 'SIM' : 'NÃO'
      }
      if (item.key == 'whenDiscardedDay') {
        this.propertiesEquipment[0].whenDiscardedDay.value = `${this.propertiesEquipment[0].whenDiscardedDay.value}/${this.propertiesEquipment[0].whenDiscardedMonth.value}/${this.propertiesEquipment[0].whenDiscardedYear.value}`
      }
      if (item.key == 'statusDevice') {
        this.propertiesEquipment[0].statusDevice.value = this.propertiesEquipment[0].statusDevice.value == 'N' ? 'NÃO LIGAVA' : 'COM DEFEITO'
      }
      if (item.key == 'otherEquipmentEnabled') {
        this.propertiesEquipment[0].otherEquipmentEnabled.value = this.propertiesEquipment[0].otherEquipmentEnabled.value == 'true' || this.propertiesEquipment[0].otherEquipmentEnabled.value == true ? 'SIM' : 'NÃO'
      }
      if (item.key == 'otherEquipmentDamaged') {
        this.propertiesEquipment[0].otherEquipmentDamaged.value = this.propertiesEquipment[0].otherEquipmentDamaged.value == 'true' || this.propertiesEquipment[0].otherEquipmentDamaged.value == true ? 'SIM' : 'NÃO'
      }
      if (item.key == 'defectWay') {
        this.propertiesEquipment[0].defectWay.value = this.propertiesEquipment[0].defectWay.value == 'true' || this.propertiesEquipment[0].defectWay.value == true ? 'SIM' : 'NÃO'
      }
      if (item.key == 'defectTime') {
        this.propertiesEquipment[0].defectTime.value = this.propertiesEquipment[0].defectTime.value == 'true' || this.propertiesEquipment[0].defectTime.value == true ? 'NA HORA' : 'MOMENTOS DEPOIS'
      }
      if (item.key == 'defectFrequency') {
        this.propertiesEquipment[0].defectFrequency.value = this.propertiesEquipment[0].defectFrequency.value == 'true' || this.propertiesEquipment[0].defectFrequency.value == true ? 'OCASIONALMENTE' : 'DE FORMA DEFINITIVA'
      }
      if (item.key == 'turnLigth') {
        this.propertiesEquipment[0].turnLigth.value = this.propertiesEquipment[0].turnLigth.value == 'true' || this.propertiesEquipment[0].turnLigth.value == true ? 'SIM' : 'NÃO'
      }
      if (item.key == 'carbonization') {
        this.propertiesEquipment[0].carbonization.value = this.propertiesEquipment[0].carbonization.value == 'true' || this.propertiesEquipment[0].carbonization.value == true ? 'SIM' : 'NÃO'
      }
      if (item.key == 'pictureTurnLigth' || item.key == 'pictureCarbonization' || item.key == 'pictureSavePart') {
        this.data[1]['items'].push({ label: this.propertiesEquipment[0][item.key].label, value: item.value });
        this.propertiesEquipment[0][item.key].disabled = true;
      }
    }
    this.isLoading = true;
  }

  getPhotos() {
    // this.loaderService.isLoading.next(true);
    this.inspectionService.getCollection('inspection_location_photos').pipe(take(1))
      .subscribe(res => {


        let facade_location
        let number_location
        let panel_circuit
        let square_light
        res.forEach(doc => {

          switch (doc.id) {
            case 'facade_location':
              facade_location = { label: 'Fachada do local', value: doc.data().payload }
              break;
            case 'number_location':
              number_location = { label: 'Foto do numero do local', value: doc.data().payload };
              break;
            // case 'panel_circuit':
            //   panel_circuit = { label: 'Foto do disjuntor local', value: doc.data().payload };
            //   break;
            case 'square_light':
              square_light = { label: 'Foto do quadro de luz interno', value: doc.data().payload }
              break;
          }
        })
        this.data[1]['items'].push(facade_location,
          number_location,
          panel_circuit,
          square_light);
      })

  }
  getMainData() {
    let data = this.coreService.getCurrentInspectionData
    this.data[0]['items'].push(
      { label: 'Apólice', value: data.PolicyNumber, disabled: false },
      { label: 'NROº Processo', value: data.ClaimCode, disabled: false },
      { label: 'Segurado', value: data.InsuredName, disabled: false },
      { label: 'Seguradora', value: data.InsuranceName, disabled: false },
      { label: 'Causa', value: data.CoverageName, disabled: false },
      { label: 'Data do ocorrido', value: data.LossDate, disabled: false })




    // this.loaderService.isLoading.next(false);
  }

  async presentModal(uuid: string, format: string, downloadUrl) {
    const name = `${uuid.slice(0, 7)}.${format}`;
    const modal = await this.modalController.create({
      component: PreviewComponent,
      componentProps: {
        'title': name,
        'downloadUrl': downloadUrl
      }
    });
    this.loadService.isLoading.next(true)
    return await modal.present();
  }

}
