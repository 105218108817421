import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BooleanComponent } from '../components/forms/boolean/boolean.component';
import { CheckboxComponent } from '../components/forms/checkbox/checkbox.component';
import { CommentComponent } from '../components/forms/comment/comment.component';
import { DropdownComponent } from '../components/forms/dropdown/dropdown.component';
import { FileComponent } from '../components/forms/file/file.component';
import { InputComponent } from '../components/forms/input/input.component';
import { RadiogroupComponent } from '../components/forms/radiogroup/radiogroup.component';
import { SubmitComponent } from '../components/forms/submit/submit.component';
import { PictureComponent } from '../components/picture/picture.component';
import { PanelComponent } from '../components/forms/panel/panel.component';
import { PanelDynamicComponent } from '../components/forms/paneldynamic/paneldynamic.component';
import { VideoComponent } from '../components/forms/video/video.component';
import { RequestDocumentsComponent } from '../components/forms/requestdocuments/requestdocuments.component';
import { CoreService } from '../services/core.service';
import { MatrixdropdownComponent } from '../components/forms/matrixdropdown/matrixdropdown.component';

const componentMapper = {
  text: InputComponent,
  dropdown: DropdownComponent,
  radiogroup: RadiogroupComponent,
  checkbox: CheckboxComponent,
  comment: CommentComponent,
  boolean: BooleanComponent,
  file: PictureComponent,
  submit: SubmitComponent,
  panel: PanelComponent,
  paneldynamic: PanelDynamicComponent,
  video: VideoComponent,
  requestdocuments: RequestDocumentsComponent,
  matrixdropdown: MatrixdropdownComponent,
  // storedVideo: StoredVideoComponent,
  storedVideo: FileComponent
};

@Directive({
  selector: '[DynamicField]'
})
export class DynamicFieldDirective implements OnInit {

  @Input() inspectionData: any;
  @Input() field: any;
  @Input() group: FormGroup;

  componentRef: any;

  constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) { }

  ngOnInit(): void {
    let factory = null
    let customProps = {};
    let componentName = '';
    if (this.field.type == 'file') {
      if ((this.field.acceptedTypes || "").includes('video')) {
        componentName = ((this.inspectionData || {}).hasAccessToMakeVideo ? 'video' : 'storedVideo');
        factory = this.resolver.resolveComponentFactory(componentMapper[componentName]);
      } else {
        if (!componentMapper[this.field.type]) return;
        factory = this.resolver.resolveComponentFactory(componentMapper[this.field.type]);
      }
    }
    else if (this.field.type == 'autosurvey-requestdocuments') {
      factory = this.resolver.resolveComponentFactory(componentMapper['requestdocuments']);
    }
    else {
      if (!componentMapper[this.field.type]) return;
      factory = this.resolver.resolveComponentFactory(componentMapper[this.field.type]);

    }

    this.componentRef = this.container.createComponent(factory);

    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;

    //PanelDynamic recebe os dados da inspeção pois ele injeta um app-dynamic-form
    if (this.field.type == 'paneldynamic')
      this.componentRef.instance.inspectionData = this.inspectionData;

    if (componentName == 'storedVideo') {
      this.componentRef.instance.hasAccessToMakeVideo = false;
    }
  }
}
